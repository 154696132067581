import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    CardHeader,
    CardBody,
    InputGroup,
    InputGroupAddon,
    Label
} from "reactstrap";
import Background from "../../components/background";
import InputMask from "react-input-mask";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import axios from "axios";
import api from "../../Services/api";
import _api from "../../Services/_api";
import "./styles.css";
import { history } from "../../helper/history";

import ReactGA from "react-ga";

class Login extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",
            telephone: "",
            showButtomPrompt: false
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/loginComNumero");
        this.deferredPrompt = null;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.beforeInstallPrompt = this.beforeInstallPrompt.bind(this);
    }

    componentDidMount() {
        window.addEventListener(
            "beforeinstallprompt",
            this.beforeInstallPrompt
        );
    }

    beforeInstallPrompt(e) {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // console.log("EE ", e);
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        // Update UI to notify the user they can add to home screen
        this.setState(() => ({ showButtomPrompt: true }));
        // console.log("showButtomPrompt", this.state.showButtomPrompt);
    }
    handleButtonClick(e) {
        this.setState(() => ({ showButtomPrompt: false }));
        // console.log("handleButtonClick");
        // Show the prompt
        this.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then(choiceResult => {
            if (choiceResult.outcome === "accepted") {
                // console.log("User accepted the A2HS prompt");
            } else {
                // console.log("User dismissed the A2HS prompt");
            }
            this.deferredPrompt = null;
        });
    }

    handleSubmit = async e => {
        e.preventDefault();

        const smsLimit = 5; // Max 5 SMS
        const blockTime = 15 * 60 * 1000; // 15 minutes in milliseconds
        const currentTime = new Date().getTime();

        // Get stored values from localStorage
        let smsHistory = JSON.parse(localStorage.getItem('smsHistory')) || [];

        // Filter out any entries older than 15 minutes
        smsHistory = smsHistory.filter(sms => currentTime - sms < blockTime);

        // If no recent SMS exists, clear the smsHistory to reset
        if (smsHistory.length === 0) {
            localStorage.removeItem('smsHistory');
        }

        if (smsHistory.length >= smsLimit) {
            // Block SMS if the limit is reached
            NotificationManager.error(
                `Você já enviou ${smsLimit} tokens. Tente novamente em 15 minutos.`,
                "Limite de Tokens",
                5000
            );
            return;
        }

        this.setState({ loading: true });
        await api
            .post("/api/login", { Telefone: this.state.telephone })
            .then(async response => {
                // console.log("response", response);

                // Save the new SMS sending time
                smsHistory.push(currentTime);
                localStorage.setItem('smsHistory', JSON.stringify(smsHistory));

                await localStorage.setItem("id", response.data.id);

                if (response.data.authenticated) {

                    response.data.userIdInt = response.data.userId;
                    response.data.userId = response.data.usuarioGuid;

                    localStorage.setItem("telephone", this.state.telephone);

                    await localStorage.setItem(
                        "token",
                        JSON.stringify(response.data)
                    );
                    await localStorage.setItem(
                        "login",
                        JSON.stringify({
                            userNumber: `${this.state.telephone}`,
                            type: "number"
                        })
                    );
                    await localStorage.setItem(
                        "email",
                        response.data.email
                    );

                    await _api
                        .post("/token", {
                            userNumber: `${this.state.telephone}`,//"+5517996714116".replace(/\D/g,""),
                            type: "number"
                        })
                        .then(async response => {
                            await localStorage.setItem("id", response.data.id);
                            history.push("/token");
                        })
                        .catch(e => {
                            // console.log(e);
                        });
                } else {
                    // NotificationManager.error(
                    //     "Não achamos o número em nosso registro",
                    //     "Número inexistente",
                    //     5000
                    // );
                    NotificationManager.error(
                        response.data.message,
                        "Falha no login",
                        5000
                    );
                    this.setState({ loading: false });
                }
            });
    };

    componentWillMount = async () => {
        if (
            (await localStorage.getItem("token")) &&
            (await localStorage.getItem("_token"))
        )
            this.setState({ redirect: "/home" });
        else if (await localStorage.getItem("token"))
            this.setState({ redirect: "/token" });

        await localStorage.removeItem("login");
        document.title = `SH - Login`;
    };
    // UNSAFE_componentWillMount = async () => {
    //     if (
    //         (await localStorage.getItem("token")) &&
    //         (await localStorage.getItem("_token"))
    //     )
    //         this.setState({ redirect: "/home" });
    //     else if (await localStorage.getItem("token"))
    //         this.setState({ redirect: "/token" });

    //     await localStorage.removeItem("login");
    //     document.title = `SH - Login`;
    // };

    render() {
        return (
            <>
                <NotificationContainer />
                {/* <Background imagePath="https://cdn-shonline.s3.us-east-2.amazonaws.com/sh_bg.gif" /> */}
                <Background imagePath="https://cdn-shonline.s3.us-east-2.amazonaws.com/video-sh-digital.mp4" />
                <div className="login-container">
                    <Container>
                        <Row>
                            <Col
                                sm={{ size: 12 }}
                                md={{ size: 6, offset: 3 }}
                                xl={{ size: 4, offset: 4 }}
                            >
                                <Card>
                                    <CardHeader
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <img src={"./images/logo.svg"} />
                                    </CardHeader>
                                    <CardBody>
                                        <form onSubmit={this.handleSubmit}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <img
                                                        src={
                                                            "./icons/cell_phone.svg"
                                                        }
                                                        style={{
                                                            marginTop: "-10px",
                                                        }}
                                                    />
                                                </InputGroupAddon>
                                                <Label>
                                                    CELULAR COM DDD
                                                </Label>
                                            </InputGroup>
                                            <InputGroup>
                                                <InputMask
                                                    mask={`+99 (99) 99999-9999`}
                                                    className="form-control"
                                                    value={this.state.telephone}
                                                    required
                                                    onChange={e => {
                                                        this.setState({
                                                            telephone: e.target.value.replace(
                                                                /\D/g,
                                                                ""
                                                            )
                                                        });
                                                    }}
                                                    type="tel"
                                                    placeholder=""
                                                    style={{
                                                        letterSpacing: "1px"
                                                    }}
                                                />
                                            </InputGroup>
                                            <div className="login-links">
                                                <a
                                                    href='https://api.whatsapp.com/send?phone=5521988446160&text=Ol%C3%A1!'
                                                    target="_blank"
                                                    className="login-link"
                                                    block
                                                    style={{
                                                        width: "100%",
                                                        marginBottom: "0.5em"
                                                    }}
                                                >
                                                    Não consegue acessar?
                                                </a>
                                                <Link
                                                    to="/user-registration"
                                                    className="login-link"
                                                    block
                                                    style={{
                                                        width: "100%",
                                                        marginBottom: "0.5em"
                                                    }}
                                                >Realizar Cadastro
                                                </Link>
                                            </div>
                                            <div
                                                className="button-Container"
                                            >
                                                <Button
                                                    type="submit"
                                                    block
                                                    disabled={this.state.loading}
                                                >
                                                    PROSSEGUIR
                                                </Button>
                                            </div>
                                        </form>
                                        {/* <Button color="link" block>
                                            <Link
                                                style={{ color: "#f8e73f" }}
                                                to="/login-email"
                                            >
                                                Tentar com e-mail
                                            </Link>
                                        </Button> */}
                                        <br />
                                        {/* <Button
                                            color="link"
                                            block
                                            style={{ color: "#f8e73f" }}
                                            onClick={
                                                () => history.push("/login-email")
                                            }
                                        >
                                            Tentar com e-mail                                            
                                        </Button>
                                        <br />                                                                            */}

                                        {/* {this.state.showButtomPrompt && (
                                            <Button
                                                type="button"
                                                color="success"
                                                block
                                                onClick={this.handleButtonClick}
                                            >
                                                Adicionar app a tela inicial
                                            </Button>
                                        )} */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
export default Login;
