import React, { Component } from "react";
// import "../../pages/Dashboard/styles.css";
// import "./styles.scss";
import "./styles.css";
import {
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Button,
    Form,
    FormGroup,
    Tooltip,
    Label,
    Input,
    Alert
} from "reactstrap";
import api from "../../Services/api.js";
import _api from "../../Services/_api.js";
import axios from "axios";
import util from "../../util";
import Header from "../../components/header";
import { Redirect, Link } from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import Sidebar from "../../components/sidebar";
import InputMask from "react-input-mask";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import moment from "moment";
import "moment/locale/pt-br";
import ContractsPicker from "../../components/contract_selector";
import InfiniteScroll from 'react-infinite-scroll-component';
import ProgressExample from "../ProgressExample/index.js";
import "./styles.css";
import "./agendamentos-style.css";
import ProgressBar from "@ramonak/react-progress-bar";
import Chart from "react-apexcharts";
import ReactGA from "react-ga";

import "./styles.css";
import { FaEyeSlash, FaEye, FaCalendarAlt, FaCalendarCheck, FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import NumberFormat from "react-number-format";
import ContractOptions from "../../components/contract_options";
import "moment/locale/pt-br";
import { button as buttonTheme } from "../../themes";
import { history } from "../../helper/history";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();

        const yearOptions = [];
        for (let i = 0; i <= 5; i++) {
            yearOptions.push(currentYear - i);
        }
        this.state = {
            currentYear: currentMonth === 0 ? currentYear - 1 : currentYear, // If January, move to last year
            currentMonth: currentMonth === 0 ? 11 : currentMonth - 1, // Previous monthmonth
            isFirstLoad: true, // Track first load
            agendamentos: [], // to store the agendamentos
            yearOptions: yearOptions,
            redirect: "",
            // loading: false,
            isLoading: true,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : (localStorage.getItem('userContract') ? JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid : null),
            moves: [],
            movesFilter: [],
            dataYesterday: moment().subtract(1, 'days').format("YYYY-MM-DD"),
            contractDetails: {},
            contractMoves: [],
            contractStats: [
                {
                    date: new Date(),
                    action: "Saiu do centro de controle",
                    id: 1
                },
                {
                    date: new Date(),
                    action: "Aguardando aprovação das mercadorias",
                    id: 2
                },
                { date: new Date(), action: "Separando mercadorias", id: 3 },
                { date: new Date(), action: "Contrato aprovado", id: 4 }
            ],
            statsModal: false,
            actualStats: true,
            contractConsumption: [],
            selectedConsumption: {},
            balance_kg: 0,
            balance_cap: 0,
            balance_completed: 0,
            balanceList: [],
            mobile: false,
            tooltipStages: [false, false, false, false, false, false],
            lastTooltip: false,
            showLeaseBalance: true,

            currentMonth: new Date().getMonth(), // 0 is January, 11 is December

            observacoes: "",
            trechoouobservacoes: "",
            action: "",
            cep: "",
            cidade: "",
            rua: "",
            numero: "",
            comp: "",
            data: "",
            total: "R$ 0,00",
            productList: [],
            addressList: [],
            enderecoSH: "",
            tamanhoCaminhao: "",

            redirect: "",
            ceploading: false,
            loading: false,
            // selectedContract: null,

            userId: JSON.parse(localStorage.getItem('token')).userId,
            tipoMovimentacao: "",
            tipoVisitaComercial: "",
            assuntoVisitaComercial: "",
            observacoesVisitaComercial: "",
            dataInicioVisitaComercial: moment(new Date()).format("YYYY-MM-DD"),
            dataInicio: moment(new Date()).format("YYYY-MM-DD"),
            dataTermino: moment(new Date()).format("YYYY-MM-DD"),
            equipamento: "",
            contractDetails: {},
            // selectedContract: {},
            selectedContract: JSON.parse(localStorage.getItem('userContract')),
            current: [],
            prev: -10,
            next: 10,
            hasMore: true,
            graphicSeries: [
                {
                    x: 'JAN',
                    y: 1000
                }, {
                    x: 'FEV',
                    y: 1952
                }, {
                    x: 'MAR',
                    y: 2400
                }, {
                    x: 'ABR',
                    y: 1800
                }, {
                    x: 'MAI',
                    y: 3000
                }, {
                    x: 'JUN',
                    y: 1500
                }, {
                    x: 'JUL',
                    y: 500
                }, {
                    x: 'AGO',
                    y: 1000
                }, {
                    x: 'SET',
                    y: 1250
                }, {
                    x: 'OUT',
                    y: 1000
                }, {
                    x: 'NOV',
                    y: 2000
                }, {
                    x: 'DEZ',
                    y: null
                }],
            series: [{
                name: 'Consumo',
                data: [
                    {
                        x: 'JAN',
                        y: 1000
                    }, {
                        x: 'FEV',
                        y: 1952
                    }, {
                        x: 'MAR',
                        y: 2400
                    }, {
                        x: 'ABR',
                        y: 1800
                    }, {
                        x: 'MAI',
                        y: 3000
                    }, {
                        x: 'JUN',
                        y: 1500
                    }, {
                        x: 'JUL',
                        y: 500
                    }, {
                        x: 'AGO',
                        y: 1000
                    }, {
                        x: 'SET',
                        y: 1250
                    }, {
                        x: 'OUT',
                        y: 1000
                    }, {
                        x: 'NOV',
                        y: 2000
                    }, {
                        x: 'DEZ',
                        y: null
                    }]
            }],
            options: {
                markers: {
                    // size: 0,
                    size: [4, 7],
                    colors: '##F6CD45',
                    fillColor: '##F6CD45',
                    strokeColors: '##F6CD45',
                    discrete: [
                        {
                            seriesIndex: 0,
                            dataPointIndex: 0,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                        {
                            seriesIndex: 0,
                            dataPointIndex: 1,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                        {
                            seriesIndex: 0,
                            dataPointIndex: 2,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                        {
                            seriesIndex: 0,
                            dataPointIndex: 3,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                        {
                            seriesIndex: 0,
                            dataPointIndex: 4,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                        {
                            seriesIndex: 0,
                            dataPointIndex: 5,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                        {
                            seriesIndex: 0,
                            dataPointIndex: 6,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                        {
                            seriesIndex: 0,
                            dataPointIndex: 7,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                        {
                            seriesIndex: 0,
                            dataPointIndex: 8,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                        {
                            seriesIndex: 0,
                            dataPointIndex: 9,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                        {
                            seriesIndex: 0,
                            dataPointIndex: 10,
                            fillColor: '#F6CD45',
                            strokeColor: '#F6CD45',
                            size: 4,
                            shape: "circle"
                        },
                    ]
                },
                colors: ['#C5C5C5', '##F6CD45'],
                chart: {
                    height: 350,
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                    // https://apexcharts.com/docs/options/chart/toolbar/
                },
                dataLabels: {
                    enabled: false,
                    formatter: function (val, opts) {
                        return `R$ ${val.toString().replace('.', ',')}`;
                    },
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'category',
                },
                tooltip: {
                    // x: {
                    //     format: 'dd/MM/yy HH:mm'
                    // },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                    marker: {
                        show: true,
                    },
                },
            },
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/dashboard");
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitVisitaComercial = this.handleSubmitVisitaComercial.bind(this);
    }

    setupDrives = () => {
        util.itemsList(this.state.contractId).then(productList => {
            for (let product of productList) {
                product.quantity = 0;
                product.valorUnitario = product.valor / product.qtdeObra;
            }
            this.setState({ productList });
        });

        api.get("/api/filial/all").then(response => {
            const addressListArray = [];
            for (let branch of response.data) {
                let text = `${branch.uf} - ${branch.logradouro}, ${branch.numero} - ${branch.cep}, ${branch.cidade}`

                addressListArray.push(text);
            }

            this.setState({
                // addressList: [...this.state.addressList, text]
                addressList: addressListArray
            });
        });
    };

    removeDuplicates = (agendamentos) => {
        const uniqueAgendamentos = [];
        const seen = new Set();

        agendamentos.forEach((agendamento) => {
            if (!seen.has(agendamento.idEntidade)) { // Assuming idEntidade is unique
                seen.add(agendamento.idEntidade);
                uniqueAgendamentos.push(agendamento);
            }
        });

        return uniqueAgendamentos;
    };

    changeMonth = (increment) => {
        this.setState((prevState) => {
            let newMonth = prevState.currentMonth + increment;
            let newYear = prevState.currentYear;

            if (newMonth > 11) {
                newMonth = 0;
                newYear++;
            } else if (newMonth < 0) {
                newMonth = 11;
                newYear--;
            }

            return { currentMonth: newMonth, currentYear: newYear };
        }, () => {
            this.fetchAgendamentos(); // Fetch agendamentos after state update
        });
    };


    changeYear = (event) => {
        const selectedYear = parseInt(event.target.value);
        this.setState({ currentYear: selectedYear }, () => {
            this.fetchAgendamentos(); // Fetch agendamentos after state update
        });
    };

    // generateDaysOfMonth = (year, month) => {
    //     const daysInMonth = moment(`${year}-${month + 1}`, "YYYY-MM").daysInMonth(); // Add 1 to month here
    //     let daysArray = [];

    //     for (let day = 1; day <= daysInMonth; day++) {
    //         daysArray.push(moment(`${year}-${month + 1}-${day}`, "YYYY-MM-DD").format("YYYY-MM-DD")); // Add 1 to month here
    //     }

    //     return daysArray;
    // };
    generateDaysOfMonth = (year, month) => {
        // Return the first day of the selected month/year
        return [moment(`${year}-${month + 1}-01`, "YYYY-MM-DD").format("YYYY-MM-DD")];
    };

    fetchAgendamentos = () => {
        const { currentYear, currentMonth } = this.state;
        const firstDayArray = this.generateDaysOfMonth(currentYear, currentMonth); // Only the first day of the month
        const { contractId } = this.state;

        // Create a promise for the first day of the selected month
        const promises = firstDayArray.map(day => {
            return api.get(`/api/contrato/${contractId}/Agendamentos/${day}`);
        });

        // Use Promise.all to wait for the request to complete
        Promise.all(promises)
            .then((responses) => {
                let allAgendamentos = [];
                responses.forEach(response => {
                    allAgendamentos = allAgendamentos.concat(response.data);
                });

                // Remove duplicates before setting the state
                const uniqueAgendamentos = this.removeDuplicates(allAgendamentos);

                // Update state with the distinct agendamentos
                this.setState({ agendamentos: uniqueAgendamentos });
            })
            .catch(error => {
                console.error("Error fetching agendamentos:", error);
            });
    };



    listGraphicSeries = (contractConsumption) => {

        if (contractConsumption) {
            const reversedAray = contractConsumption.reverse();
            const monthsToShow = 12;
            const slicedArray = reversedAray.slice(0, monthsToShow);
            const ordereArray = slicedArray.reverse();
            const consumptionSeries = [];

            ordereArray.map((item) => {
                let monthName = '';
                const month = item.dataHora.split('/')[0];
                switch (month) {
                    case '01':
                        monthName = 'JAN'
                        break;
                    case '02':
                        monthName = 'FEV'
                        break;
                    case '03':
                        monthName = 'MAR'
                        break;
                    case '04':
                        monthName = 'ABR'
                        break;
                    case '05':
                        monthName = 'MAI'
                        break;
                    case '06':
                        monthName = 'JUN'
                        break;
                    case '07':
                        monthName = 'JUL'
                        break;
                    case '08':
                        monthName = 'AGO'
                        break;
                    case '09':
                        monthName = 'SET'
                        break;
                    case '10':
                        monthName = 'OUT'
                        break;
                    case '11':
                        monthName = 'NOV'
                        break;
                    case '12':
                        monthName = 'DEZ'
                        break;

                }
                consumptionSeries.push({
                    x: monthName,
                    y: item.valor
                });
            })

            // this.setState({ graphicSeries: consumptionSeries });
            this.setState({
                series: [{
                    name: 'Consumo',
                    data: consumptionSeries,
                    formatter: function (val, opts) {
                        return `R$ ${val.toString().replace('.', ',')}`;
                    },
                }],
            });


        }
    }

    contractMoves = () => {
        api.get(
            `/api/contrato/${this.state.contractId}/MovimentacoesEquipamento`
        )
            .then(response => {
                // // console.log("moves: ", response.data);

                let moves = response.data;

                this.setState({ moves, movesFilter: moves }, this.fetch);
            })
            .catch(err => {
                // console.log(err);
            });
    };

    setTooltipStage(index) {
        this.setState(state => {
            state.tooltipStages[index] = !state.tooltipStages[index];
            return { tooltipStages: state.tooltipStages };
        });
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 1000 });
    }

    toggleStatsModal = () => {
        this.setState({ statsModal: !this.state.statsModal });
    };

    showValue = (value) => {
        if (this.state.contractDetails && this.state.contractDetails[`${value}`]) return this.state.contractDetails[`${value}`]
        else return '-'
    }

    getContractDetails = async id => {
        const { dataYesterday } = this.state;

        const promises = [
            api.get(`/api/contrato/${id}/detalhes`),
            // api.get(`/api/contrato/${id}/saldo/${moment().format("YYYY-MM-DD")}`),
            api.get(`/api/contrato/${id}/saldo/${dataYesterday}`),
            api.get(`/api/contrato/${id}/Agendamentos/${moment().format("YYYY-MM-DD")}`),
            api.get(`/api/contrato/${id}/consumo`)
        ];
        await Promise.all(promises)
            .then(response => {
                for (let x = 0; x < response.length; x++) {
                    let resp = response[x];
                    switch (x) {
                        case 0:
                            this.setState({
                                contractDetails: resp.data[0]
                            });
                            if (resp.data.length > 0)
                                document.title = `SH Digital`;
                            break;
                        case 1:
                            let maxValue = 0;
                            let maxKg = 0;
                            let balanceArray = [];
                            for (let item of resp.data) {
                                if (item.faturamentoFechado) {
                                    maxValue += item.valor;
                                }
                                maxKg += item.pesoTotalEq;

                                balanceArray.push({
                                    balanceDate: item.data,
                                    balanceValue: item.valor,
                                });
                            }

                            const a = maxKg.toFixed(2) / maxValue.toFixed(2) * 100;
                            const b = Math.round((a - 100));

                            this.setState({
                                balance_kg: maxKg.toFixed(2),
                                balance_cap: maxValue.toFixed(2),
                                balanceList: balanceArray,
                                balance_completed: b,
                            });


                            break;
                        case 2:
                            // console.log('contractMoves');
                            // console.log(resp.data);
                            this.setState({
                                contractMoves: resp.data
                            });
                            break;
                        case 3:
                            // console.log('listStatus');
                            // console.log(resp.data);
                            const listStatus = () => {
                                const validStatus = ['ContratoAprovado', 'ContratoComCartaDePecas', 'ContratoEncerrado']
                                return resp.data.listaStatus.filter(status => {
                                    return validStatus.includes(status)
                                })
                            }
                            this.setState({
                                contractConsumption: resp.data.consumo,
                                contractStats: listStatus(),
                                actualStats: resp.data.statusAtual
                            });
                            this.listGraphicSeries(resp.data.consumo);
                            // console.log('Status atual: ', this.state.actualStats)
                            break;
                    }
                }
                this.setState({ isLoading: false });
            })
            .catch(error => { });
    };

    getContracts = () => {
        api.get(`/api/usuario/${this.state.userId}/contratos`)
            .then(response => {
                let userContracts = response.data;
                // // console.log(response.data);
                userContracts.sort((a, b) => b.anoProposta - a.anoProposta || b.idPropostaContrato - a.idPropostaContrato);

                // setUserContracts(userContracts);
                // setUserContractsFiltro(userContracts);

                this.setContract(this.state.contractId, response.data);

            }).catch(error => {
                // console.log(error);
            })
    }

    cepInfos = async cep => {
        this.setState({ ceploading: true });
        await axios
            .get(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => {
                let { logradouro, localidade } = response.data;
                this.setState({ rua: logradouro, cidade: localidade });
            })
            .catch(error => {
                NotificationManager.error(
                    "Não conseguimos localizar seu endereço.",
                    "CEP incorreto",
                    5000
                );
            });
        this.setState({ ceploading: false });
    }

    clearForm() {
        const newProductList = this.state.productList.map(e => {
            e.quantity = '0'
            return e
        })
        this.setState({
            action: '', cep: '', cidade: '', rua: '', numero: '', comp: '',
            enderecoSH: '', data: '', total: 'R$ 0,00',
            productList: newProductList,
            tamanhoCaminhao: ''
        })
    }

    replaceWithExplicitLowercase(input) {
        const replacements = {
            A: 'a', B: 'b', C: 'c', D: 'd', E: 'e', F: 'f', G: 'g',
            H: 'h', I: 'i', J: 'j', K: 'k', L: 'l', M: 'm', N: 'n',
            O: 'o', P: 'p', Q: 'q', R: 'r', S: 's', T: 't', U: 'u',
            V: 'v', W: 'w', X: 'x', Y: 'y', Z: 'z'
        };

        const aaa = input.replace(/[A-Z]/g, (match) => replacements[match] || match);
        // console.log(aaa);
        return input.replace(/[A-Z]/g, (match) => replacements[match] || match);
    }

    handleSubmit = async e => {
        // console.log('iniciando submit');
        e.preventDefault()
        // console.log('preventDefault');
        this.setState({ loading: true })
        let {
            action,
            cep,
            cidade,
            rua,
            numero,
            comp,
            data,
            dataInicio,
            enderecoSH,
            productList,
            tamanhoCaminhao,
            trechoouobservacoes,
        } = this.state;
        let productMessage = ``
        // const validProducts = productList.filter(p => p.quantity > 0)
        // validProducts.map(product => {
        //     const isLastProduct = validProducts.indexOf(product) === validProducts.length - 1
        //     productMessage += `${product.equipamento} - ${product.quantity} unidades${isLastProduct ? '.' : ','} `
        // })
        // console.log('productMessage');
        // console.log(productMessage);

        const selectedUserContract = this.state.selectedContract;
        // console.log(selectedUserContract);
        const contractString = selectedUserContract ? `${selectedUserContract.numeroProposta ? selectedUserContract.numeroProposta.toString().padStart(4, "0") : ""}/${selectedUserContract.anoProposta}-${selectedUserContract.nomeUnidade} - ${selectedUserContract.nomeObra}` : "";
        // const actionText = action == 'Retirada'
        //     ? 'Retirar os seguintes equipamentos:'
        //     : 'Devolver os equipamentos previamente retirados'
        const actionText = action == 'Retirada'
            ? `Contrato ${contractString} - Retirar os seguintes equipamentos:`
            : `Contrato ${contractString} - Devolver os equipamentos previamente retirados`

        let fullAddress = `${rua}, ${numero}, ${comp} - ${cidade}, ${cep}`

        if (trechoouobservacoes) {
            fullAddress = `${fullAddress} - Trecho ou Obserção: ${trechoouobservacoes}`;
        }
        const date = moment(dataInicio).format("LL");
        debugger;
        const unity = enderecoSH
        try {
            const { nomeCliente, emailFuncLogistica, emailVendedor, emailAsTec, emailSac, emailSupProjetos } = this.state.contractDetails;

            // console.log(selectedUserContract);

            let toList = [];

            // if (selectedUserContract) {
            //     toList.push(`operacoes_${selectedUserContract.nomeUnidade.toLowerCase()}@sh.com.br`);
            //     // console.log(`e-mail de logistica`);
            //     // console.log(`operacoes_${selectedUserContract.nomeUnidade.toLowerCase()}@sh.com.br`);
            //     debugger;
            // }
            if (selectedUserContract) {
                // console.log(`e-mail de operações replace`);
                // const nomeUnidadeLowerCase = this.replaceWithExplicitLowercase(selectedUserContract.nomeUnidade);
                const nomeUnidadeLowerCase = selectedUserContract.nomeUnidade.toLowerCase();
                // console.log(nomeUnidadeLowerCase);
                const operationsEmail = `operacoes_${nomeUnidadeLowerCase}@sh.com.br`
                toList.push(operationsEmail);
                // console.log(`e-mail de logistica`);
                // console.log(operationsEmail);
                debugger;
            }

            if (emailFuncLogistica)
                toList.push(emailFuncLogistica);
            if (emailVendedor)
                toList.push(emailVendedor);
            if (emailAsTec)
                toList.push(emailAsTec);
            if (emailSupProjetos)
                toList.push(emailSupProjetos);

            toList.push("thiago.neves@kroonar.com");

            // console.log(emailFuncLogistica, emailVendedor, emailAsTec, emailSac, emailSupProjetos);

            // console.log('lista de emails');
            // console.log(toList);

            // console.log("Lista de destinatários (toList):", toList);
            // console.log("Tipo de ação (action):", action);
            // console.log("Nome do cliente (nomeCliente):", nomeCliente);
            // console.log("Email de suporte (emailSac):", emailSac);
            // console.log("Texto da ação (actionText):", actionText);
            // console.log("Tamanho do caminhão (tamanhoCaminhao):", tamanhoCaminhao);
            // console.log("Endereço completo (fullAddress):", fullAddress);
            // console.log("Data (date):", date);
            // console.log("Unidade (unity):", unity);
            debugger;

            await _api.post('/mail', {
                to: toList,
                type: action,
                user: nomeCliente,
                email: emailSac,
                actionText,
                truckSize: tamanhoCaminhao,
                fullAddress,
                date, unity
            });

            this.setState({ loading: false })
            this.clearForm()
            NotificationManager.success(
                "Solicitação de agendamento confirmada. Aguarde confirmação ou sugestão de nova data por e-mail!\nObrigado. Equipe de Logística",
                "Sucesso",
                10000
            )
        } catch (err) {
            debugger;
            this.setState({ loading: false })
            NotificationManager.error(
                "Error ao enviar solicitação, tente novamente mais tarde",
                "Erro",
                4000
            )
            throw err
        }
    };

    handleSubmitVisitaComercial = async e => {
        // console.log('iniciando submit');
        e.preventDefault()
        // console.log('preventDefault');
        this.setState({ loading: true })
        let {
            contractId,
            contractDetails,
            selectedUserContract,
            dataInicioVisitaComercial,
            tipoVisitaComercial,
            assuntoVisitaComercial,
            observacoesVisitaComercial
        } = this.state;

        // console.log(selectedUserContract);

        const contractString = selectedUserContract ? `${selectedUserContract.numeroProposta ? selectedUserContract.numeroProposta.toString().padStart(4, "0") : ""}/${selectedUserContract.anoProposta}-${selectedUserContract.nomeUnidade} - ${selectedUserContract.nomeObra}` : "";

        const date = moment(dataInicioVisitaComercial).format("LL");

        try {
            const { nomeCliente, emailVendedor, emailSac } = this.state.contractDetails

            await _api.post('/newEmail', {
                to: [emailVendedor, emailSac],
                subject: "SH - Solicitação de visita comercial",
                template: "commercialVisit",
                body: {
                    contractId: contractString,
                    userName: nomeCliente,
                    userEmail: localStorage.getItem("email"),
                    userPhone: localStorage.getItem("telephone"),
                    date,
                    tipoVisitaComercial,
                    assuntoVisitaComercial,
                    observacoesVisitaComercial
                }
            });

            this.setState({ loading: false })

            NotificationManager.success(
                "Sua solicitação de agendamento de visita comercial foi registrada! Entraremos em contato para confirmar o mais breve possível.",
                "Sucesso",
                5000)

            setTimeout(() => history.push("/home"), 5000);

        } catch (err) {
            this.setState({ loading: false })
            NotificationManager.error(
                "Error ao enviar solicitação, tente novamente mais tarde",
                "Erro",
                4000
            )
            throw err
        }
    };

    setContract = async (id, data) => {
        // console.log(id);
        const dataUserContracts = data;
        // const userSelected = dataUserContracts.find(e => e.idPropostaContrato === id)
        // if (id) {
        //     const userSelected = dataUserContracts.filter(e => {
        //         return e.idPropostaContrato === id
        //     });
        //     localStorage.setItem('userContract', JSON.stringify(userSelected))
        //     this.setState({ selectedContract: userSelected, contractId: data[0].idPropostaContrato });

        //     // console.log(this.state.selectedContract);
        //     // console.log(this.state.contractId);
        // }
        if (!id) {
            const contractDefined = data[0];
            const contractIdDefined = contractDefined.idPropostaContrato;
            localStorage.setItem('userContract', JSON.stringify(contractDefined))
            this.setState({ selectedContract: contractDefined, contractId: contractIdDefined });

            this.getContractDetails(contractIdDefined);
            this.contractMoves(contractIdDefined);
        }
        // // console.log(userSelected);
        // setSelectedUserContract(userSelected);
        // let { origin, pathname } = window.location
        // window.location = id == 0 ? `${origin}/home` : `${origin}${pathname}?contract=${id}`
    }

    // UNSAFE_componentWillMount = () => {
    //     api.defaults.headers.common = {
    //         Authorization: `Bearer ${
    //             JSON.parse(localStorage.getItem("token")).accessToken
    //         }`
    //     };
    //     this.getContractDetails(this.state.contractId);
    //     // document.title = `${document.title} - Nova Movimentação`
    // };

    componentDidMount() {
        if (this.state.isFirstLoad) {
            this.setState({ isFirstLoad: false }, () => {
                this.fetchAgendamentos(); // Fetch agendamentos after state has been set to previous month
            });
        }
    }

    filter = () => {
        const { moves, tipoMovimentacao, dataInicio, dataTermino, equipamento } = this.state;

        // data: "2021-05-10 11:46:45"
        // nmEquipamento: "Escora Super SH Fêmea"
        // nrProposta: "0001/2018-SAO"
        // numero: 24494
        // qtEquipamento: 84
        // tipo: "NF"

        var movesFilter = moves;

        if (tipoMovimentacao) {
            let tipo = tipoMovimentacao == "Retirada" ? "NF" : "ND";
            movesFilter = movesFilter.filter(move => (move.tipo == tipo));

            // let tipo = tipoMovimentacao == "Retirada" ? "RE" : "ND";

            // if (tipoMovimentacao == "Retirada") {
            //     movesFilter = movesFilter.filter(move => (move.tipo == tipo));
            // }
            // else {
            //     movesFilter = movesFilter.filter(move => (move.tipo !== tipo));
            // }
        }

        if (dataInicio) {
            let data = moment(dataInicio);

            movesFilter = movesFilter.filter(move => (moment(move.data.substring(0, 10)).toDate().getTime() >= data.toDate().getTime()));
        }

        if (dataTermino) {
            let data = moment(dataTermino);

            movesFilter = movesFilter.filter(move => (moment(move.data.substring(0, 10)).toDate().getTime() <= data.toDate().getTime()));
        }

        if (equipamento) {
            movesFilter = movesFilter.filter(move => (move.nmEquipamento.toLowerCase().indexOf(equipamento.toLocaleLowerCase()) > -1));
        }

        this.setState({ movesFilter, current: [], prev: -10, next: 10, hasMore: true }, this.fetch);

        // // console.log(movesFilter);
    }

    fetch = () => {
        const { movesFilter, current, prev, next, hasMore } = this.state;

        // // console.log('movesFilter: ', movesFilter);
        // // console.log('current: ', current);
        // // console.log('prev: ', prev);
        // // console.log('next: ', next);

        if (current.length === movesFilter.length) {
            let hasMore = false;

            this.setState({ hasMore });

            return;
        }

        let proximaPagina = current.concat(movesFilter.slice(prev + 10, next + 10));

        // // console.log('proximaPagina: ', proximaPagina);

        this.setState((prevState) => { return { ...prevState, current: proximaPagina, prev: prevState.prev + 10, next: prevState.next + 10, hasMore } });
    }

    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        _api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token")).accessToken
                }`
        };
        api.interceptors.response.use(
            res => res,
            error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    history.push("/login");
                }
                return Promise.reject(error);
            }
        );
        this.getContracts();
        if (this.state.contractId) {
            this.contractMoves(this.state.contractId);
            if (this.state.contractId == 0) {
                document.title = `SH - Home`;
            }
            this.getContractDetails(this.state.contractId);
            this.setupDrives();
        }
    };

    // showContractDetails = async id => {
    //     if (id !== 0) {
    //         this.setState({ contractId: id });
    //         await this.getContractDetails(id);
    //     } else {
    //         this.setState({ isLoading: false });
    //     }
    // };

    // componentWillMount = () => {
    //     api.defaults.headers.common = {
    //         Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
    //             }`
    //     };
    //     api.interceptors.response.use(
    //         res => res,
    //         error => {
    //             if (error.response.status === 401) {
    //                 localStorage.clear();
    //                 history.push("/login");
    //             }
    //             return Promise.reject(error);
    //         }
    //     );
    //     window.addEventListener("resize", this.resize.bind(this));
    //     this.resize();
    //     this.showContractDetails(this.state.contractId);
    //     if (this.state.contractId == 0) document.title = `SH - Digital`;
    // };

    renderStats = onModal => {
        let newStats = [];
        for (let x = 0; x < this.state.contractStats.length; x++) {
            if (x !== this.state.contractStats.length - 1) {
                if (
                    x <=
                    this.state.contractStats.indexOf(this.state.actualStats)
                ) {
                    newStats.push({
                        status: this.state.contractStats[x],
                        passed: true
                    });
                } else {
                    newStats.push({
                        status: this.state.contractStats[x],
                        passed: false
                    });
                }
            }
        }
        return (
            <div className={`status-container ${onModal ? "in-modal" : ""}`}>
                <div className="delimiter first"></div>
                <div
                    className={`line ${this.state.actualStats == "ContratoEncerrado"
                        ? "finished"
                        : ""
                        }`}
                >
                    {newStats.map((value, i) => {
                        return (
                            <div
                                key={i}
                                style={{
                                    width: `${100 / newStats.length -
                                        100 /
                                        newStats.length /
                                        (newStats.length + 1)}%`,
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: value.passed
                                            ? "#e42c2a"
                                            : "#7c7c7c",
                                        width: "100%",
                                        height: value.passed ? 3 : 1
                                    }}
                                ></div>
                                <div
                                    id={`checkpoint${i}`}
                                    className="checkpoint-area"
                                >
                                    <div
                                        className={
                                            value.passed
                                                ? "checkpoint"
                                                : "dontcheck"
                                        }
                                    >
                                        {value.passed ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                                            </svg>
                                        ) : null}
                                        {value.status ==
                                            this.state.actualStats ? (
                                            <div className="checkpoint-bubbly">
                                                <span>
                                                    {value.status
                                                        .toString()
                                                        .replace(
                                                            /([A-Z])/g,
                                                            " $1"
                                                        )
                                                        .replace(/^./, function (
                                                            str
                                                        ) {
                                                            return str.toUpperCase();
                                                        })}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <Tooltip
                                    target={`checkpoint${i}`}
                                    placement="bottom"
                                    isOpen={this.state.tooltipStages[i]}
                                    toggle={() => this.setTooltipStage(i)}
                                >
                                    {value.status
                                        .toString()
                                        .replace(/([A-Z])/g, " $1")
                                        .replace(/^./, function (str) {
                                            return str.toUpperCase();
                                        })}
                                </Tooltip>
                            </div>
                        );
                    })}
                </div>
                <div
                    id="last-checkpoint"
                    className={`delimiter ${this.state.actualStats == "ContratoEncerrado"
                        ? "last"
                        : ""
                        }`}>
                    <div
                        style={{
                            width: `${100 / newStats.length -
                                100 /
                                newStats.length /
                                (newStats.length + 1)}%`,
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        <div
                            id="last-checkpoint"
                            className="checkpoint-area"
                        >
                            <div
                                className={
                                    this.state.actualStats == "ContratoEncerrado"
                                        ? "checkpoint"
                                        : "dontcheck"
                                }
                            >
                                {this.state.actualStats == "ContratoEncerrado" ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                                    </svg>
                                ) : null}
                                {this.state.actualStats ==
                                    "ContratoEncerrado" ? (
                                    <div className="checkpoint-bubbly">
                                        <span>
                                            {this.state.actualStats
                                                .toString()
                                                .replace(
                                                    /([A-Z])/g,
                                                    " $1"
                                                )
                                                .replace(/^./, function (
                                                    str
                                                ) {
                                                    return str.toUpperCase();
                                                })}
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <Tooltip
                    target="last-checkpoint"
                    placement="bottom"
                    isOpen={this.state.lastTooltip}
                    toggle={() =>
                        this.setState(state => ({
                            lastTooltip: !state.lastTooltip
                        }))
                    }
                >
                    Contrato Encerrado
                </Tooltip>
            </div>
        );
    };

    renderConsumption = () => {
        // WIDTH -> 90%
        let consumptionTotal = 0;
        for (let consumption of this.state.contractConsumption) {
            consumptionTotal += consumption.valor;
        }
        let mean = consumptionTotal / this.state.contractConsumption.length;
        let past =
            this.state.contractConsumption.length > 1
                ? this.state.contractConsumption[
                    this.state.contractConsumption.length - 2
                ].valor
                : 0;
        let actually =
            this.state.contractConsumption.length > 0
                ? this.state.contractConsumption[
                    this.state.contractConsumption.length - 1
                ].valor
                : 0;

        return (
            <div className="consumption-container">
                <div className="consumption-feedback">
                    <div
                        className="consumption-mean consumption-line"
                        style={{
                            width:
                                mean > past && mean > actually
                                    ? "100%"
                                    : past > actually
                                        ? `${util.remap(mean, 0, past, 0, 100) < 35
                                            ? 35
                                            : util.remap(
                                                mean,
                                                0,
                                                past,
                                                0,
                                                100
                                            )
                                        }%`
                                        : `${util.remap(
                                            mean,
                                            0,
                                            actually,
                                            0,
                                            100
                                        ) < 35
                                            ? 35
                                            : util.remap(
                                                mean,
                                                0,
                                                actually,
                                                0,
                                                100
                                            )
                                        }%`
                        }}
                    >
                        R$ {util.currenzialize(mean || 0)}
                    </div>
                    <div
                        className="consumption-past-month consumption-line"
                        style={{
                            width:
                                past > mean && past > actually
                                    ? "100%"
                                    : mean > actually
                                        ? `${util.remap(past, 0, mean, 0, 100) < 35
                                            ? 35
                                            : util.remap(
                                                past,
                                                0,
                                                mean,
                                                0,
                                                100
                                            )
                                        }%`
                                        : `${util.remap(
                                            past,
                                            0,
                                            actually,
                                            0,
                                            100
                                        ) < 35
                                            ? 35
                                            : util.remap(
                                                past,
                                                0,
                                                actually,
                                                0,
                                                100
                                            )
                                        }%`
                        }}
                    >
                        R$ {util.currenzialize(past || 0)}
                    </div>
                    <div
                        className="consumption-month consumption-line"
                        style={{
                            width:
                                actually > past && actually > mean
                                    ? "100%"
                                    : past > mean
                                        ? `${util.remap(
                                            actually,
                                            0,
                                            past,
                                            0,
                                            100
                                        ) < 35
                                            ? 35
                                            : util.remap(
                                                actually,
                                                0,
                                                past,
                                                0,
                                                100
                                            )
                                        }%`
                                        : `${util.remap(
                                            actually,
                                            0,
                                            mean,
                                            0,
                                            100
                                        ) < 35
                                            ? 35
                                            : util.remap(
                                                actually,
                                                0,
                                                mean,
                                                0,
                                                100
                                            )
                                        }%`
                        }}
                    >
                        R$ {util.currenzialize(actually || 0)}
                    </div>
                </div>
                <div className="consumption-subtitle">
                    <div className="subtitle">
                        <div
                            className="color"
                            style={{ backgroundColor: "#7c7c7c" }}
                        ></div>
                        <b style={{ marginLeft: 4 }}>Média</b>
                    </div>
                    <div className="subtitle">
                        <div
                            className="color"
                            style={{ backgroundColor: "#f8e73f" }}
                        ></div>
                        <b style={{ marginLeft: 4 }}>
                            {this.state.contractConsumption.length > 1
                                ? this.state.contractConsumption[
                                    this.state.contractConsumption.length - 2
                                ].dataHora
                                : "-"}
                        </b>
                    </div>
                    <div className="subtitle">
                        <div
                            className="color"
                            style={{ backgroundColor: "#e42c2a" }}
                        ></div>
                        <b style={{ marginLeft: 4 }}>
                            {this.state.contractConsumption.length > 0
                                ? this.state.contractConsumption[
                                    this.state.contractConsumption.length - 1
                                ].dataHora
                                : "-"}
                        </b>
                        {/* <span></span> */}
                    </div>
                </div>
            </div>
        );
    };

    renderMoves = () => {
        if (this.state.contractMoves && this.state.contractMoves.length) {
            return this.state.contractMoves.map((value, i) => {
                return (
                    // <div key={i} className="move-box">
                    //     {value.dataAgendamento}
                    // </div>
                    <div key={i} className="move-box">
                        <div className="item-infos">
                            <span className="item-quantity">
                                {value.tpChecklist ? <FaArrowAltCircleUp style={{ fill: "#1bbd14", width: 25, height: 25 }} /> : <FaArrowAltCircleDown style={{ fill: "#e42c2a", width: 25, height: 25 }} />}
                            </span>
                            <div className="right">
                                <span className="item-description">
                                    {`${moment(value.dataAgendamento).format("L LT")}`}{" "}
                                </span>
                                <span class="item-description d-block text-left" style={{ fontSize: 12 }}><b>Tipo de caminhão</b><br /> {value.inCaminhao}</span>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return "Sem programações"
        }

    };

    mobileLayout = props => {
        return (
            <>
                <Row className="contract-options">
                    <Col md={{ size: 4, offset: 2 }}>
                        <div
                            className="session-status"
                            onClick={() => {
                                this.toggleStatsModal();
                            }}
                        >
                            <div className="session-label margin-label">
                                ETAPA DO CONTRATO
                            </div>
                            {this.renderStats()}
                        </div>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <div className="session-consumption">
                            <div className="session-label margin-label">
                                CONSUMO DO CONTRATO
                            </div>
                            {this.renderConsumption()}
                        </div>
                    </Col>
                    <Col md={{ size: 4, offset: 2 }} xs={{ size: 6 }}>
                        <div className="session-balance">
                            <div className="session-label margin-label">
                                SALDO ATUAL EM OBRA
                            </div>
                            <span>Material</span>
                            <span style={{ textAlign: "center" }}>
                                <span className="highlight-input">
                                    {util.currenzialize(this.state.balance_kg)}
                                </span>{" "}
                                <span className="normal-weight">kg</span>
                            </span>
                            <Link
                                to={`/drives?contract=${this.state.contractId}`}
                                className="btn btn-red btn-block btn-moves mt-3"
                                style={{ ...buttonTheme, ...{ fontSize: 9 } }}
                            >
                                NOVA RETIRADA/DEVOLUÇÃO
                            </Link>
                            {/* <span>Locação</span>
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                {this.state.showLeaseBalance ? (
                                    <>
                                        R$ &nbsp;{" "}
                                        <span className="highlight-input">
                                            {util.currenzialize(
                                                this.state.balance_cap
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    <div className="hide-lease-balance" />
                                )}
                                {this.state.showLeaseBalance ? (
                                    <FaEye
                                        onClick={() =>
                                            this.setState(state => ({
                                                showLeaseBalance: !state.showLeaseBalance
                                            }))
                                        }
                                        className="icon"
                                    />
                                ) : (
                                    <FaEyeSlash
                                        onClick={() =>
                                            this.setState(state => ({
                                                showLeaseBalance: !state.showLeaseBalance
                                            }))
                                        }
                                        className="icon"
                                    />
                                )}
                            </span> */}
                        </div>
                    </Col>
                    <Col md={{ size: 4 }} xs={{ size: 6 }}>
                        <div className="session-movement">
                            <div className="session-label margin-label mb-4">
                                AGENDAMENTOS
                            </div>
                            <div className="moves-container ">
                                {/* {this.renderMoves()} */}
                            </div>
                        </div>
                    </Col>
                    <ContractOptions />
                </Row>
            </>
        );
    };

    desktopLayout = props => {
        return (
            <>
                <Row className="contract-options">
                    <Col md={{ size: 4, offset: 2 }}>
                        <Row>
                            <div
                                className="session-status"
                                onClick={() => {
                                    this.toggleStatsModal();
                                }}
                            >
                                <div className="session-label margin-label">
                                    ETAPA DO CONTRATO
                                </div>
                                {this.renderStats()}
                            </div>
                        </Row>
                        <Row>
                            <div className="session-consumption">
                                <div className="session-label margin-label">
                                    CONSUMO DO CONTRATO
                                </div>
                                {this.renderConsumption()}
                            </div>
                        </Row>
                    </Col>
                    <Col md={{ size: 2 }} xs={{ size: 6 }}>
                        <div className="session-movement">
                            <div className="session-label margin-label mb-4">
                                AGENDAMENTOS
                            </div>
                            <div className="moves-container ">
                                {/* {this.renderMoves()} */}
                            </div>
                        </div>
                    </Col>
                    <Col md={{ size: 2 }} xs={{ size: 6 }}>
                        <div className="session-balance">
                            <div className="session-label margin-label">
                                SALDO ATUAL EM OBRA
                            </div>
                            <br />
                            <span>Material</span>
                            <span style={{ textAlign: "center" }}>
                                <span className="highlight-input">
                                    {util.currenzialize(this.state.balance_kg)}
                                </span>{" "}
                                <span className="normal-weight">kg</span>
                            </span>
                            <Link
                                to={`/drives?contract=${this.state.contractId}`}
                                className="btn btn-red btn-block btn-moves mt-4"
                                style={buttonTheme}
                            >
                                NOVA RETIRADA/DEVOLUÇÃO
                            </Link>
                            {/* <a
                                href={`https://surveyv2.binds.co/#/link/alR8o2dVGBQgr319xqQ01AqLU6yAdYy57Z3n5Yk6MjwL1vXN/?loop=true&close=true&timeout=30&`}
                                target="_blank"
                                className="btn btn-red btn-block btn-moves mt-4"
                                style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    backgroundColor: 'purple',
                                    borderColor: 'purple'
                                  }}
                            >
                                FAÇA A PESQUISA DE SATISFAÇÃO
                            </a> */}
                            <br />
                            {/* <span>Locação</span>
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                {this.state.showLeaseBalance ? (
                                    <>
                                        R$ &nbsp;{" "}
                                        <span className="highlight-input">
                                            {util.currenzialize(
                                                this.state.balance_cap
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    <div className="hide-lease-balance" />
                                )}
                                {this.state.showLeaseBalance ? (
                                    <FaEye
                                        onClick={() =>
                                            this.setState(state => ({
                                                showLeaseBalance: !state.showLeaseBalance
                                            }))
                                        }
                                        className="icon"
                                    />
                                ) : (
                                    <FaEyeSlash
                                        onClick={() =>
                                            this.setState(state => ({
                                                showLeaseBalance: !state.showLeaseBalance
                                            }))
                                        }
                                        className="icon"
                                    />
                                )}
                            </span> */}
                        </div>
                    </Col>
                    <ContractOptions />
                </Row>
            </>
        );
    };

    noContractLayout = props => (
        <Row className="contract-options">
            <Col
                md={{ size: 12 }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: '1.5rem'
                }}
            >
                <Alert color="warning">
                    Nenhum contrato foi encontrado!
                </Alert>
            </Col>
        </Row>
    );

    loadingLayout = props => (
        <Row className="contract-options">
            <Col
                md={{ size: 12 }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                {/* Carregando{" "} */}
                <Spinner style={{ marginLeft: "1rem", color: "#7c7c7c" }} />
            </Col>
        </Row>
    );

    render() {
        const monthNames = [
            "JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO",
            "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"
        ];

        return (
            <div className="dashboard-container">
                <Header />
                {/* <Breadcrumbs
                    items={[
                        {
                            title: "Home",
                            link: `/home?contract=${this.state.contractId}`
                        },
                        {
                            title: "Movimentações",
                            link: `/moves?contact=${this.state.contractId}`
                        },
                        { title: "Histórico" }
                    ]}
                />
                <ContractsPicker /> */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                    }}
                >
                    <Sidebar />
                    <div
                        style={{
                            width: "100%",
                            paddingTop: "20px",
                        }}
                    >
                        <NotificationContainer />
                        {(this.state.selectedContract && (
                            <>
                                <Row className="section-row">
                                    <div className="col-md-6">
                                        <div
                                            className='section-title-container'
                                        >
                                            <div
                                                className='section-title'
                                            >
                                                <img
                                                    src='./icons/dashboard/resumo_contrato.svg'
                                                />
                                                Resumo do contrato
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="title">
                                                <div className="text">
                                                    {/* NÚMERO: 1067/2020-SAO */}
                                                    NÚMERO: {this.state.selectedContract.numeroProposta ? this.state.selectedContract.numeroProposta.toString().padStart(4, "0") : ""}/{this.state.selectedContract.anoProposta}-{this.state.selectedContract.nomeUnidade} - {this.state.selectedContract.nomeObra}
                                                </div>
                                                <div className="action">
                                                    <a href="#">detalhes do contrato</a>
                                                </div>
                                            </div>
                                            <div className="contract-data-row">
                                                <div className="contract-data-col">
                                                    <div className="contract-data-label">
                                                        CONTRATANTE:
                                                    </div>
                                                    <div className="contract-data-value">
                                                        {/* KM 18 EMPREENDIMENTO IMOBILIÁRIO SPE LTDA */}
                                                        {this.state.contractDetails.nomeContratante || "-"}
                                                    </div>
                                                </div>
                                                <div className="contract-data-col">
                                                    <div className="contract-data-label">
                                                        COMERCIAL:
                                                    </div>
                                                    <div className="contract-data-value">
                                                        {/* BRUNO DA SILVA NEVES <br />
                                                bruno.neves@sh.com.br <br />
                                                11 9845-4820 <br /> */}
                                                        {this.state.contractDetails.nomeVendedor || "-"} <br />
                                                        {this.state.contractDetails.emailVendedor || "-"} <br />
                                                        {/* {this.state.contractDetails.nomeVendedor || "-"} <br /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="contract-data-row">
                                                <div className="contract-data-col">
                                                    <div className="contract-data-label">
                                                        CLIENTE:
                                                    </div>
                                                    <div className="contract-data-value">
                                                        {/* Ekko Group Incorporacoes e Participacoes S.A. */}
                                                        {this.state.contractDetails.nomeCliente || "-"}
                                                    </div>
                                                    <br />
                                                    <div className="contract-data-label">
                                                        CNPJ DO CLIENTE:
                                                    </div>
                                                    <div className="contract-data-value">
                                                        {/* 12.235.478/0001-44 */}
                                                        {/* {this.state.contractDetails.Cnpj} */}
                                                        {this.showValue('cnpjCliente')}
                                                    </div>
                                                </div>
                                                <div className="contract-data-col">
                                                    <div className="contract-data-label">
                                                        ASSISTENTE TÉCNICO:
                                                    </div>
                                                    <div className="contract-data-value">
                                                        {this.state.contractDetails.asTec || "-"} <br />
                                                        {this.state.contractDetails.emailAsTec || "-"} <br />
                                                        {/* 11 9845-4820 <br /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="contract-data-row">
                                                <div className="contract-data-col">
                                                    <div className="contract-data-label">
                                                        {(this.state.selectedContract.nomeUnidade === 'SSA') ? 'GESTOR DA UNIDADE:' : 'GERENTE DA UNIDADE:'}

                                                    </div>
                                                    <div className="contract-data-value">
                                                        {/* Kleber Goncalves Bittencourt */}
                                                        {this.state.contractDetails.gerenteUnidade || "-"}
                                                    </div>
                                                </div>
                                                <div className="contract-data-col">
                                                    <div className="contract-data-label">
                                                        SAC:
                                                    </div>
                                                    <div className="contract-data-value">
                                                        {/* sao@sh.com.br */}
                                                        {this.state.contractDetails.emailSac || "-"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div
                                            className='section-title-container'
                                        >
                                            <div
                                                className='section-title'
                                            >
                                                <img
                                                    src='./icons/dashboard/status_operacao.svg'
                                                />
                                                Status da operação
                                            </div>
                                        </div>
                                        <div className="card">
                                            {/* <div style={{
                                        margin: "auto"
                                    }}>
                                        <b>Em construção</b>
                                    </div> */}
                                            <div className="title">
                                                <div className="text">
                                                    {(this.state.agendamentos && this.state.agendamentos[0]) ? (
                                                        <>
                                                            {/* PEDIDO 008794598 */}
                                                            PEDIDO {this.state.agendamentos[0].idEntidade}
                                                        </>
                                                    ) : (
                                                        <>
                                                            PEDIDO --
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {(this.state.agendamentos && this.state.agendamentos[0]) && (
                                                <ProgressExample
                                                    agendamentos={this.state.agendamentos}
                                                    statusCaminhao={this.state.agendamentos[0].concluida}
                                                />
                                            )}
                                            <div className="step-details">
                                                <div className="step-details-title">
                                                    <div className="icon">
                                                        <img
                                                            src='./icons/dashboard/truck.svg'
                                                        />
                                                    </div>
                                                    <div className="text">
                                                        {(this.state.agendamentos) ? (
                                                            <>
                                                                {(this.state.actualStats === 'ContratoAprovado') && (
                                                                    <>
                                                                        CONTRATO APROVADO
                                                                    </>
                                                                )}
                                                                {(this.state.actualStats === 'ContratoEncerrado') && (
                                                                    <>
                                                                        CONTRATO ENCERRADO
                                                                    </>
                                                                )}
                                                                {(this.state.actualStats === 'ContratoComCartaDePecas') && (
                                                                    <>
                                                                        CONTRATO COM CARTA DE PEÇAS
                                                                    </>
                                                                )}
                                                                {/* {this.state.contractMoves.idEntidade} */}
                                                            </>
                                                        ) : (
                                                            <>
                                                                --
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="step-details-subtitle">
                                                    {(this.state.contractMoves && this.state.contractMoves[0]) ? (
                                                        <>
                                                            {
                                                                (this.state.contractMoves[this.state.contractMoves.length - 1].dataAgendamento) &&
                                                                (
                                                                    moment(this.state.contractMoves[this.state.contractMoves.length - 1].dataAgendamento).format('DD/MM/YYYY - HH:mm')
                                                                )
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            __/__/____ - __:__
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <Row className="section-row">
                                    <div className="col-md-6">
                                        <div
                                            className='section-title-container'
                                        >
                                            <div
                                                className='section-title'
                                            >
                                                <img
                                                    src='./icons/dashboard/consumo_contrato.svg'
                                                />
                                                Consumo do contrato
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-top-part">
                                                {/* <div className="mixed-chart">
                                             <Chart
                                                options={this.state.options}
                                                series={this.state.series}
                                                type="line"
                                            // width="300"
                                            // height={368}
                                            // height="368"
                                            /> 
                                        </div> */}
                                                <Chart
                                                    options={this.state.options}
                                                    series={this.state.series}
                                                    type="area"
                                                    height={215}
                                                />
                                            </div>
                                            <div className="card-bottom-part">
                                                <div className="progressbar">
                                                    {/*https://www.npmjs.com/package/@ramonak/react-progress-bar*/}
                                                    {/*<ProgressBar
                                                        maxCompleted={100}
                                                        completed={this.state.balance_completed}
                                                        height="28px"
                                                        width="100%"
                                                        bgColor="#F6CD45"
                                                        baseBgColor="#F0F0F0"
                                                        labelColor="#FFF"
                                                        animateOnRender={true}
                                                        labelSize="11px"
                                                        transitionDuration='3s'
                                                    />*/}

                                                    <div className="saldo-weight">
                                                        {/* 1.952,00 KG */}
                                                        <img
                                                            src='./images/img_balanca.png'
                                                        />
                                                        {util.currenzialize(this.state.balance_kg)}
                                                    </div>
                                                    <div className="saldo-title">
                                                        Saldo da obra (KG)
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div style={{
                                        margin: "auto"
                                    }}>
                                        <b>Em construção</b>
                                    </div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div
                                            className='section-title-container'
                                        >
                                            <div
                                                className='section-title'
                                            >
                                                <img
                                                    src='./icons/dashboard/agendamentos.svg'
                                                />
                                                Agendamentos
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="agendamentos-container">
                                                <div className="header-container">
                                                    <div className="year-selection-container">
                                                        {/* <select className="year-selector">
                                                            <option>2023</option>
                                                            <option selected>2024</option>
                                                            <option>2025</option>
                                                        </select> */}
                                                        <select className="year-selector" value={this.state.currentYear} onChange={this.changeYear}>
                                                            {Array.from({ length: 11 }, (_, i) => this.state.currentYear - i).map(year => (
                                                                <option key={year} value={year}>{year}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="month-selection-container">
                                                        {/* <div className="month-control-container">
                                                            <div className="month-change-control">
                                                                <img src={"./icons/dashboard/arrow-left.svg"} />
                                                            </div>
                                                            <div className="month-name-big">
                                                                Maio
                                                            </div>
                                                            <div className="month-change-control">
                                                                <img src={"./icons/dashboard/arrow_right.svg"} />
                                                            </div>
                                                        </div> */}
                                                        <div className="month-control-container">
                                                            <div className="month-change-control" onClick={() => this.changeMonth(-1)}>
                                                                <img src={"./icons/dashboard/arrow-left.svg"} alt="Previous Month" />
                                                            </div>
                                                            <div className="month-name-big">
                                                                {monthNames[this.state.currentMonth]}
                                                            </div>
                                                            <div className="month-change-control" onClick={() => this.changeMonth(1)}>
                                                                <img src={"./icons/dashboard/arrow_right.svg"} alt="Next Month" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="body-container">
                                                    {
                                                        <div style={{
                                                            margin: "auto"
                                                        }}>

                                                            {(!this.state.agendamentos) && (
                                                                <b>Sem programações</b>
                                                            )}

                                                            {this.state.agendamentos && this.state.agendamentos.map((agendamento, index) => (
                                                                <>
                                                                    <div className="appointment-date-container">
                                                                        <div className="date-container">
                                                                            <div className="day-of-year-container">
                                                                                {/* QUA */}
                                                                                {agendamento.dataAgendamento && (moment(agendamento.dataAgendamento).format('ddd').toUpperCase())}
                                                                            </div>
                                                                            <div className="day-of-month-container">
                                                                                {agendamento.dataAgendamento && (moment(agendamento.dataAgendamento).date())}
                                                                            </div>
                                                                        </div>
                                                                        <div className="title-arrow-container">
                                                                            <div className="appointment-title-container">
                                                                                {/* {agendamento.tpChecklist ? 'DEVOLUÇÃO' : 'RETIRADA'} - ({moment(agendamento.dataAgendamento).format('DD/MM/YYYY')}) */}
                                                                                {agendamento.hre ? 'DEVOLUÇÃO' : 'RETIRADA'} - ({moment(agendamento.dataAgendamento).format('DD/MM/YYYY')})
                                                                            </div>
                                                                            <div className="arrow-container">
                                                                                {(!agendamento.hre) ? (
                                                                                    <img src={"./icons/dashboard/devolucao.svg"} />
                                                                                ) : (
                                                                                    <img src={"./icons/dashboard/retirada.svg"} />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div key={index} className="agendamento-item">
                                                                        <div>
                                                                            <strong>Data:</strong> {moment(agendamento.date).format('DD/MM/YYYY')}
                                                                        </div>
                                                                        <div>
                                                                            <strong>Tipo:</strong> {agendamento.tpChecklist ? 'DEVOLUÇÃO' : 'RETIRADA'}
                                                                        </div>
                                                                    </div> */}
                                                                </>
                                                            ))}
                                                        </div>
                                                    }
                                                    {/* <div className="appointment-date-container">
                                                <div className="date-container">
                                                    <div className="day-of-year-container">
                                                        QUA
                                                    </div>
                                                    <div className="day-of-month-container">
                                                        11
                                                    </div>
                                                </div>
                                                <div className="title-arrow-container">
                                                    <div className="appointment-title-container">
                                                        DEVOLUÇÃO - (11/10/2023)
                                                    </div>
                                                    <div className="arrow-container">
                                                        <img src={"./icons/dashboard/devolucao.svg"} />
                                                    </div>
                                                </div>
                                            </div> */}
                                                    {/* <div className="appointment-date-container">
                                                <div className="date-container">
                                                    <div className="day-of-year-container">
                                                        QUI
                                                    </div>
                                                    <div className="day-of-month-container">
                                                        12
                                                    </div>
                                                </div>
                                                <div className="title-arrow-container">
                                                    <div className="appointment-title-container">
                                                        RETIRADA - (12/10/2023)
                                                    </div>
                                                    <div className="arrow-container">
                                                        <img src={"./icons/dashboard/retirada.svg"} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="appointment-date-container">
                                                <div className="date-container">
                                                    <div className="day-of-year-container">
                                                        SEX
                                                    </div>
                                                    <div className="day-of-month-container">
                                                        13
                                                    </div>
                                                </div>
                                                <div className="title-arrow-container">
                                                    <div className="appointment-title-container">
                                                        DEVOLUÇÃO - (13/10/2023)
                                                    </div>
                                                    <div className="arrow-container">
                                                        <img src={"./icons/dashboard/devolucao.svg"} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="appointment-date-container">
                                                <div className="date-container">
                                                    <div className="day-of-year-container">
                                                        SAB
                                                    </div>
                                                    <div className="day-of-month-container">
                                                        14
                                                    </div>
                                                </div>
                                                <div className="title-arrow-container">
                                                    <div className="appointment-title-container">
                                                        DEVOLUÇÃO - (14/10/2023)
                                                    </div>
                                                    <div className="arrow-container">
                                                        <img src={"./icons/dashboard/devolucao.svg"} />
                                                    </div>
                                                </div>
                                            </div> */}
                                                </div>
                                            </div>
                                            {/* <img
                                        src='./icons/dashboard/agendamentos_demo.svg'
                                        style={{
                                            // margin: '25px',
                                            height: '368px',
                                            // maxWidth: '439px',
                                        }}
                                    /> */}
                                        </div>
                                    </div>
                                </Row>
                            </>
                        ))}
                        <Row className="section-row">
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="colored-title red">
                                        <div className="action">
                                            <a style={{ textDecoration: 'none' }} href="#">+</a>
                                        </div>
                                        <div className="text">
                                            NOVA VISITA
                                        </div>
                                    </div>
                                    <form onSubmit={this.handleSubmitVisitaComercial}>
                                        <div className="form-contract-data-row">
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19, marginTop: 38 }}>
                                                    <Label>AGENDAR PARA:</Label>
                                                    <Input
                                                        type="date"
                                                        name="dataInicioVisitaComercial"
                                                        value={this.state.dataInicioVisitaComercial}
                                                        placeholder="__/__/____"
                                                        onChange={e => {
                                                            if (
                                                                moment(
                                                                    e.target.value,
                                                                    "YYYY-MM-DD"
                                                                ).format("YYYY-MM-DD") ===
                                                                e.target.value
                                                            ) {
                                                                this.setState({ dataInicioVisitaComercial: moment(e.target.value).format("YYYY-MM-DD") }, this.filter);

                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>TIPO DE VISITA:</Label>
                                                    <select
                                                        className="form-control"
                                                        required
                                                        onChange={e => {
                                                            this.setState({
                                                                tipoVisitaComercial: e.target.value
                                                            });
                                                        }}
                                                        value={this.state.tipoVisitaComercial}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="Visita comercial">Solicitar visita comercial</option>
                                                        <option value="visita de Astec">Solicitar visita de Astec</option>
                                                    </select>
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>ASSUNTO:</Label>
                                                    <Input
                                                        type="text"
                                                        name="assunto"
                                                        value={this.state.assuntoVisitaComercial}
                                                        onChange={e => {
                                                            this.setState({ assuntoVisitaComercial: e.target.value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>OBSERVAÇÕES:</Label>
                                                    <Input
                                                        type='textarea'
                                                        name="observacoes"
                                                        value={this.state.observacoesVisitaComercial}
                                                        onChange={e => {
                                                            this.setState({ observacoesVisitaComercial: e.target.value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <div class="alert alert-danger w-100">
                                                        <h4 class="alert-heading">Atenção!</h4>
                                                        <p class="font-weight-normal">Esse procedimento é apenas de solicitação de visita comercial/visita técnica e depende da confirmação das equipes comercial e técnica da SH.</p>
                                                        <hr />
                                                        <p class="mb-0 font-weight-normal">Aguarde um retorno em breve!</p>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19, textAlign: 'right' }}>
                                                    <Button
                                                        className="btn-hollow"
                                                    // onClick={ }
                                                    >
                                                        SOLICITAR
                                                    </Button>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="colored-title yellow">
                                        <div className="action">
                                            <a style={{ textDecoration: 'none' }} href="#">+</a>
                                        </div>
                                        <div className="text">
                                            NOVO AGENDAMENTO
                                        </div>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-contract-data-row">
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19, marginTop: 38 }}>
                                                    <Label>AGENDAR PARA:</Label>
                                                    <Input
                                                        type="date"
                                                        name="dataInicio"
                                                        value={this.state.dataInicio}
                                                        placeholder="__/__/____"
                                                        onChange={e => {
                                                            if (
                                                                moment(
                                                                    e.target.value,
                                                                    "YYYY-MM-DD"
                                                                ).format("YYYY-MM-DD") ===
                                                                e.target.value
                                                            ) {
                                                                this.setState({ dataInicio: moment(e.target.value).format("YYYY-MM-DD") }, this.filter);

                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>TIPO DE OPERAÇÃO:</Label>
                                                    <select
                                                        className="form-control"
                                                        required
                                                        onChange={e => {
                                                            this.setState({
                                                                action: e.target.value
                                                            });
                                                        }}
                                                        value={this.state.action}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="Retirada">Solicitar retirada</option>
                                                        <option value="Devolução">Solicitar devolução</option>
                                                    </select>
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>TIPO DE CAMINHÃO:</Label>
                                                    <select
                                                        className="form-control"
                                                        required
                                                        onChange={e => {
                                                            this.setState({
                                                                tamanhoCaminhao: e.target.value
                                                            });
                                                        }}
                                                        value={this.state.tamanhoCaminhao}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="Até 12 tons.">Até 12 ton</option>
                                                        <option value="Maior de 12 tons.">Maior de 12 ton</option>
                                                    </select>
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>CEP:</Label>
                                                    <InputMask
                                                        required
                                                        mask={"99999-999"}
                                                        value={this.state.cep}
                                                        onChange={e => {
                                                            this.setState({
                                                                cep: e.target.value
                                                            });
                                                        }}
                                                        onBlur={() => {
                                                            this.cepInfos(
                                                                this.state.cep
                                                            );
                                                        }}
                                                        className="form-control"
                                                        placeholder="CEP"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>CIDADE:</Label>
                                                    <Input
                                                        required
                                                        disabled={this.state.ceploading}
                                                        value={this.state.cidade}
                                                        onChange={e => {
                                                            this.setState({
                                                                cidade: e.target.value
                                                            });
                                                        }}
                                                        placeholder="Cidade"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>ENDEREÇO:</Label>
                                                    <Input
                                                        required
                                                        disabled={this.state.ceploading}
                                                        value={this.state.rua}
                                                        onChange={e => {
                                                            this.setState({
                                                                rua: e.target.value
                                                            });
                                                        }}
                                                        placeholder="Endereço"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>NÚMERO:</Label>
                                                    <Input
                                                        required
                                                        value={this.state.numero}
                                                        onChange={e => {
                                                            this.setState({
                                                                numero: e.target.value
                                                            });
                                                        }}
                                                        placeholder="Número"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>COMPLEMENTO:</Label>
                                                    <Input
                                                        // required
                                                        value={this.state.comp}
                                                        onChange={e => {
                                                            this.setState({
                                                                comp: e.target.value
                                                            });
                                                        }}
                                                        placeholder="Complemento"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>TRECHO OU OBSERVAÇÕES:</Label>
                                                    <Input
                                                        type='textarea'
                                                        name="observacoes"
                                                        value={this.state.trechoouobservacoes}
                                                        onChange={e => {
                                                            this.setState({ trechoouobservacoes: e.target.value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <Label>UNIDADE SH:</Label>
                                                    <select
                                                        className="form-control"
                                                        required
                                                        onChange={e => {
                                                            this.setState({
                                                                enderecoSH:
                                                                    e.target.value
                                                            });
                                                        }}
                                                        value={this.state.enderecoRetirada}
                                                    >
                                                        <option value="">
                                                            {this.state.addressList
                                                                .length > 0
                                                                ? "Selecione uma unidade"
                                                                : "Carregando unidades..."}
                                                        </option>
                                                        {this.state.addressList.map(
                                                            (value, i) => {
                                                                return (
                                                                    <option
                                                                        key={i}
                                                                        className="address"
                                                                    >
                                                                        {value}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19 }}>
                                                    <div class="alert alert-danger w-100">
                                                        <h4 class="alert-heading">Atenção!</h4>
                                                        <p class="font-weight-normal">Esse procedimento é apenas de solicitação de agendamento e depende da confirmação da equipe de logística da SH.</p>
                                                        <hr />
                                                        <p class="mb-0 font-weight-normal">Aguarde um retorno em breve!</p>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="full-contract-data-col">
                                                <FormGroup style={{ marginBottom: 19, textAlign: 'right' }}>
                                                    <Button
                                                        className="btn-hollow"
                                                    // onClick={ }
                                                    >
                                                        SOLICITAR
                                                    </Button>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Row>
                        <Row className="section-row">
                            <div className="col-md-6">
                                <a href="/catalog">
                                    <div className="img-card">
                                        <img
                                            style={{
                                                margin: "auto",
                                                marginTop: "55px", width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: '100%'
                                            }}
                                            src={"./images/banners/catalogo.png"}
                                        />
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6">
                                <a href="/commercialvisit">
                                    <div className="img-card">
                                        <img
                                            style={{
                                                margin: "auto",
                                                marginTop: "55px", width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: '100%'
                                            }}
                                            src={"./images/banners/agendamento.png"}
                                        />
                                    </div>
                                </a>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}
