import React, { Component } from "react";
import { Row, Col, Tooltip, Input, Button, Spinner } from "reactstrap";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import api from "../../Services/api.js";
import _api from "../../Services/_api";
import util from "../../util";
import Header from "../../components/header";
import ModalEditProfile from "../../components/modal-edit-profile";
import { Redirect } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";
import { button as buttonTheme } from "../../themes";
import { history } from "../../helper/history";
import { FaPencilAlt } from "react-icons/fa";
import Sidebar from "../../components/sidebar";

import "./styles.css";

import ReactGA from "react-ga";

export default class UserInfos extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",
            userId: JSON.parse(localStorage.getItem("token")).userId,
            user: { telefone: "00000000000" },
            toolbarExit: false,
            modalName: false,
            modalEmail: false,
            modalPhoneNumber: false,
            doubtInput: "",
            doubtInputIsValid: false,
            loadingSendDoubt: false,
            firstAccess: util.queryParams().firstaccess,
            showComments: false,
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/perfilUsuario");

        // // console.log('util.queryParams().firstaccess: ', util.queryParams().firstaccess)
    }

    setModal(key, value) {
        let modalValue = null;
        this.setState(state => {
            if (value) modalValue = value;
            else modalValue = !state[`${key}`];
            return { [`${key}`]: modalValue };
        });
    }

    logout = () => {
        localStorage.clear();
        localStorage.setItem("firstAccess", true);

        this.setState({ redirect: "/login" });
    };

    getUserInfos = () => {
        api.get(`/api/usuario/${this.state.userId}`)
            .then(response => {
                this.setState({ user: response.data });
            })
            .catch(e => {
                // console.log(e);
            });
    };

    sendDoubtOrIssue = async () => {
        try {
            this.setState({ loadingSendDoubt: true });
            const params = {
                subject: "SH - Solicitação de dúvida/problema",
                body: {
                    userName: this.state.user.nome,
                    contact: util.phoneMask(this.state.user.telefone),
                    msg: this.state.doubtInput
                },
                template: 'questionOrProblem',
                to: 'digital@sh.com.br'
            };
            await _api.post("/newemail", params)
            this.setState({
                loadingSendDoubt: false,
                doubtInput: "",
                doubtInputIsValid: false
            });
            NotificationManager.success(
                "O envio de sua dúvida/problema foi enviado com sucesso",
                "Sucesso",
                4000
            );
            this.setState({ showComments: false });
        } catch (err) {
            this.setState({ loadingSendDoubt: false });
            NotificationManager.error(
                "O envio de sua dúvida/problema falhou, tente novamente mais tarde",
                "Erro",
                4000
            );
            throw err;
        }
    };

    componentWillMount = () => {
        const authorizationHeader = itemKey => {
            return {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem(itemKey)).accessToken
                    }`
            };
        };
        api.defaults.headers.common = authorizationHeader("token");
        _api.defaults.headers.common = authorizationHeader("_token");
        api.interceptors.response.use(
            res => res,
            error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    history.push("/login");
                }
                return Promise.reject(error);
            }
        );
        document.title = `SH - Informações do Usuário`;
        this.getUserInfos();
    };

    render() {
        return (
            <div className="userinfos-container">
                <NotificationContainer />
                {this.state.redirect !== "" ? (
                    <Redirect to={`${this.state.redirect}`} />
                ) : null}
                <Header activeTab="userinfos" />
                <br />
                {/* {
                    this.state.firstAccess &&
                    <Row>
                        <Col md={{ size: 8, offset: 2 }} xs={{ size: 12 }}>
                            <div class="alert alert-info w-100">
                                <h6 class="alert-heading">Bem-vindo! {this.state.user.nome}.</h6>
                                <p class="font-weight-normal">Confirme suas informações de contato abaixo. Para alterá-las clique no ícone
                                    "<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "#e42c2a", width: 18, height: 18 }}><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>"
                                    ao lado do campo que deseja alterar.</p>
                            </div>
                        </Col>
                    </Row>
                } */}

                <Row className="infos-container">
                    <Col md={{ size: 12 }}>
                        <div className="info">
                            <br />
                            <br />
                            <img src={"./icons/profile/user_profile.svg"} />
                        </div>
                    </Col>
                </Row>
                <Row className="infos-container">
                    <Col md={{ size: 12 }}>
                        <div className="info">
                            <div className="info-label">
                                Nome
                                <br />
                                <div className="info-value"
                                    onClick={() =>
                                        this.setState({ modalName: true })
                                    }
                                >
                                    {this.state.user.nome}
                                </div>
                            </div>
                            <div
                                className="info-edit"
                            // onClick={() =>
                            //     this.setState({ modalName: true })
                            // }
                            >

                            </div>
                            <ModalEditProfile
                                type="name"
                                title="Nome"
                                modal={this.state.modalName}
                                userData={this.state.user}
                                setModal={value =>
                                    this.setModal("modalName", value)
                                }
                                onSave={() => { }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="infos-container">
                    <Col md={{ size: 12 }}>
                        <div className="info">
                            <div className="info-icon">

                            </div>
                            <div className="info-label">
                                Telefone
                                <br />
                                <div className="info-value"
                                    onClick={() =>
                                        this.setState({ modalPhoneNumber: true })
                                    }>
                                    {util.phoneMask(this.state.user.telefone)}
                                </div>
                            </div>
                            <div
                                className="info-edit"
                            // onClick={() =>
                            //     this.setState({ modalPhoneNumber: true })
                            // }
                            >

                            </div>
                            <ModalEditProfile
                                type="contact"
                                title="Contato"
                                modal={this.state.modalPhoneNumber}
                                userData={this.state.user}
                                setModal={value =>
                                    this.setModal("modalPhoneNumber", value)
                                }
                                onSave={() => { }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="infos-container">
                    <Col md={{ size: 12 }}>
                        <div className="info">
                            <div className="info-icon">

                            </div>
                            <div className="info-label">
                                E-Mail
                                <br />
                                <div className="info-value"
                                    onClick={() =>
                                        this.setState({ modalEmail: true })
                                    }>
                                    {this.state.user.email}
                                </div>
                            </div>
                            <div
                                className="info-edit"
                            >

                            </div>
                            <ModalEditProfile
                                type="email"
                                title="E-mail"
                                modal={this.state.modalEmail}
                                userData={this.state.user}
                                setModal={value =>
                                    this.setModal("modalEmail", value)
                                }
                                onSave={() => { }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="infos-container">
                    <Col md={{ size: 12 }}>
                        <div className="info">
                            <div className="info-actions">
                                <div
                                    className="comment"
                                    id="button-comment"
                                    onClick={() =>
                                        this.setState({ showComments: true })
                                    }
                                >
                                    <img src={"./icons/profile/comment.svg"} />
                                    <div>
                                        Deixar comentários
                                    </div>
                                </div>
                                <Tooltip
                                    target="button-exit"
                                    placement="bottom"
                                    isOpen={this.state.toolbarExit}
                                    toggle={() =>
                                        this.setState(state => ({
                                            toolbarExit: !state.toolbarExit
                                        }))
                                    }
                                >
                                    Deixar comentários
                                </Tooltip>
                                {/* <div className="last-access">
                                    <br />
                                    <p>
                                        Último acesso:{" "}
                                        {moment(
                                            this.state.user.ultimoAcesso
                                        ).format("L LTS")}
                                    </p>
                                    <p>Versão do Sistema: 4.0.0</p>
                                </div> */}
                            </div>
                        </div>
                    </Col>
                </Row>
                {(this.state.showComments) && (
                    <Row className="infos-container">
                        <Col md={{ size: 12 }}>
                            <div className="info">
                                <div className="info-actions">
                                    <Input
                                        value={this.state.doubtInput}
                                        onChange={input => {
                                            let doubtInputIsValid = false;
                                            if (input.target.value.length > 0)
                                                doubtInputIsValid = true;
                                            else doubtInputIsValid = false;
                                            this.setState({
                                                doubtInput: input.target.value,
                                                doubtInputIsValid
                                            });
                                        }}
                                        type="textarea"
                                        placeholder="Deixar um comentário"
                                    />
                                    <Button
                                        block
                                        onClick={this.sendDoubtOrIssue}
                                        disabled={!this.state.doubtInputIsValid}
                                        color="yellow"
                                        className="btn-send"
                                        style={buttonTheme}
                                    >
                                        {this.state.loadingSendDoubt ? (
                                            <Spinner />
                                        ) : (
                                            "Enviar"
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

                <Row className="infos-container">
                    <Col md={{ size: 12 }}>
                        <div className="info">
                            <div className="info-actions">
                                <div
                                    className="exit"
                                    id="button-exit"
                                    onClick={() => {
                                        this.logout();
                                    }}
                                >
                                    <img src={"./icons/profile/logout.svg"} />
                                </div>
                                <Tooltip
                                    target="button-exit"
                                    placement="bottom"
                                    isOpen={this.state.toolbarExit}
                                    toggle={() =>
                                        this.setState(state => ({
                                            toolbarExit: !state.toolbarExit
                                        }))
                                    }
                                >
                                    Sair
                                </Tooltip>
                                {/* <div className="last-access">
                                    <br />
                                    <p>
                                        Último acesso:{" "}
                                        {moment(
                                            this.state.user.ultimoAcesso
                                        ).format("L LTS")}
                                    </p>
                                    <p>Versão do Sistema: 4.0.0</p>
                                </div> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div >
        );
    }
}
