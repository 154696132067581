import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    CardHeader,
    CardBody,
    InputGroup,
    InputGroupAddon,
    Input
} from "reactstrap";
import Background from "../../components/background";
import "./styles.css";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import api from "./../../Services/_api.js";
import { history } from "../../helper/history";

import ReactGA from "react-ga";

class Login_Email extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",
            email: ""
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/LoginWithEmail");
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = async e => {
        e.preventDefault();        
        let userEmail = await localStorage.getItem("email");
        if(this.state.email !== userEmail){
            NotificationManager.error(
                "Por favor informe o e-mail registrado na sua conta da SH!",
                "E-mail incompatível!",
                5000
            );
            this.setState({ loading: false }); 
            return           
        }       

        await localStorage.setItem(
            "login",
            JSON.stringify({
                userEmail: userEmail,
                type: "email"
            })
        );

        this.setState({ loading: true });
        await api
            .post("/token", {
                userEmail: userEmail,
                type: "email"
            })
            .then(async response => {
                await localStorage.setItem("id", response.data.id);
                history.push("/token");
            })
            .catch(e => {
                // console.log(e);
            });
    };

    componentWillMount = async () => {
        if (!(await localStorage.getItem("token")) ||
            !(await localStorage.getItem("login")) ||
            !(await localStorage.getItem("email"))){
            this.setState({ redirect: "/login" });  
        }      
        
        document.title = `SH - Login`;
    };
    // UNSAFE_componentWillMount = async () => {
    //     if (!(await localStorage.getItem("token")) ||
    //         !(await localStorage.getItem("login")) ||
    //         !(await localStorage.getItem("email"))){
    //         this.setState({ redirect: "/login" });  
    //     }      
        
    //     document.title = `SH - Login`;
    // };

    render() {
        return (
            <>
                <NotificationContainer />
                <Background imagePath="./images/background-login.jpg" />
                {this.state.redirect !== "" ? (
                    <Redirect to={`${this.state.redirect}`} />
                ) : null}
                <div className="loginemail-container">
                    <Container>
                        <Row>
                            <Col
                                sm={{ size: 12 }}
                                md={{ size: 6, offset: 3 }}
                                xl={{ size: 4, offset: 4 }}
                            >
                                <Card>
                                    <CardHeader
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <img src={"./images/logo.svg"} />
                                    </CardHeader>
                                    <CardBody>
                                        <form onSubmit={this.handleSubmit}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <img
                                                        src={
                                                            "./images/email_icon.png"
                                                        }
                                                        style={{
                                                            width: 50,
                                                            height: "100%"
                                                        }}
                                                    />
                                                </InputGroupAddon>
                                                <Input
                                                    required
                                                    type="email"
                                                    onChange={e => {
                                                        this.setState({
                                                            email:
                                                                e.target.value
                                                        });
                                                    }}
                                                    placeholder="Insira seu e-mail"
                                                    value={this.state.email}
                                                    style={{
                                                        borderColor: "#f8e73f",
                                                        paddingLeft: 10
                                                    }}
                                                />
                                            </InputGroup>
                                            <Button
                                                type="submit"
                                                color="warning"
                                                block
                                                style={{
                                                    backgroundColor: "#f8e73f",
                                                    marginTop: 30
                                                }}
                                            >
                                                PROSSEGUIR
                                            </Button>
                                        </form>
                                        <br />
                                        <Button
                                            color="link"
                                            block
                                            style={{ color: "#f8e73f" }}
                                        >
                                            <Link
                                                style={{ color: "#f8e73f" }}
                                                to="/"
                                            >
                                                Tentar com número
                                            </Link>
                                        </Button>
                                        <a 
                                            href='https://api.whatsapp.com/send?phone=5521988446160&text=Ol%C3%A1!' 
                                            target="_blank"
                                            className="btn btn-secondary"
                                            block
                                            style={{
                                                width: "100%"                                                                                             
                                            }}
                                        >
                                            Não consigo acessar
                                        </a>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
export default Login_Email;
