import React from 'react';
import './styles.css';

const Background = (props) => {
    return (
        <div className="background-app">
            <video autoPlay muted loop id="background-video"
                style={{
                    marginTop: '-9.5vh'
                }}
            >
                <source src={props.imagePath} type="video/mp4"></source>
            </video>
        </div>
    )
}

export default Background;