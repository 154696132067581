import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import "./styles.css";
import api from "./../../Services/api.js";
import {
    Row,
    Col,
    Button,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Tooltip,
    Alert
} from "reactstrap";
import util from "../../util";
import moment from "moment";
import { FaEyeSlash, FaEye, FaCalendarAlt, FaCalendarCheck, FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import NumberFormat from "react-number-format";
import ContractOptions from "../../components/contract_options";
import "moment/locale/pt-br";
import { button as buttonTheme } from "../../themes";
import { history } from "../../helper/history";

class BodyHome extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",
            isLoading: true,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : (JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid ? JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid : null),
            contractDetails: {},
            contractMoves: [],
            contractStats: [
                {
                    date: new Date(),
                    action: "Saiu do centro de controle",
                    id: 1
                },
                {
                    date: new Date(),
                    action: "Aguardando aprovação das mercadorias",
                    id: 2
                },
                { date: new Date(), action: "Separando mercadorias", id: 3 },
                { date: new Date(), action: "Contrato aprovado", id: 4 }
            ],
            statsModal: false,
            actualStats: true,
            contractConsumption: [],
            selectedConsumption: {},
            balance_kg: 0,
            balance_cap: 0,
            mobile: false,
            tooltipStages: [false, false, false, false, false, false],
            lastTooltip: false,
            showLeaseBalance: true
        };
    }

    setTooltipStage(index) {
        this.setState(state => {
            state.tooltipStages[index] = !state.tooltipStages[index];
            return { tooltipStages: state.tooltipStages };
        });
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 1000 });
    }

    toggleStatsModal = () => {
        this.setState({ statsModal: !this.state.statsModal });
    };

    getContractDetails = async id => {
        const promises = [
            api.get(`/api/contrato/${id}/detalhes`),
            api.get(
                `/api/contrato/${id}/saldo/${moment().format("YYYY-MM-DD")}`
            ),
            api.get(`/api/contrato/${id}/Agendamentos/${moment().format("YYYY-MM-DD")}`),
            api.get(`/api/contrato/${id}/consumo`)
        ];
        await Promise.all(promises)
            .then(response => {
                for (let x = 0; x < response.length; x++) {
                    let resp = response[x];
                    switch (x) {
                        case 0:
                            this.setState({
                                contractDetails: resp.data
                            });
                            if (resp.data.length > 0)
                                document.title = `SH Digital`;
                            break;
                        case 1:
                            let maxValue = 0;
                            let maxKg = 0;
                            for (let item of resp.data) {
                                if (item.faturamentoFechado) {
                                    maxValue += item.valor;
                                }
                                maxKg += item.pesoTotalEq;
                            }
                            this.setState({
                                balance_kg: maxKg.toFixed(2),
                                balance_cap: maxValue.toFixed(2)
                            });
                            break;
                        case 2:
                            this.setState({
                                contractMoves: resp.data
                            });
                            break;
                        case 3:
                            const listStatus = () => {
                                const validStatus = ['ContratoAprovado', 'ContratoComCartaDePecas', 'ContratoEncerrado']
                                return resp.data.listaStatus.filter(status => {
                                    return validStatus.includes(status)
                                })
                            }
                            this.setState({
                                contractConsumption: resp.data.consumo,
                                contractStats: listStatus(),
                                actualStats: resp.data.statusAtual
                            });
                            // console.log('Status atual: ', this.state.actualStats)
                            break;
                    }
                }
                this.setState({ isLoading: false });
            })
            .catch(error => { });
    };

    showContractDetails = async id => {
        if (id !== 0) {
            this.setState({ contractId: id });
            await this.getContractDetails(id);
        } else {
            this.setState({ isLoading: false });
        }
    };

    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        api.interceptors.response.use(
            res => res,
            error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    history.push("/login");
                }
                return Promise.reject(error);
            }
        );
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.showContractDetails(this.state.contractId);
        if (this.state.contractId == 0) document.title = `SH - Digital`;
    };

    renderStats = onModal => {
        let newStats = [];
        for (let x = 0; x < this.state.contractStats.length; x++) {
            if (x !== this.state.contractStats.length - 1) {
                if (
                    x <=
                    this.state.contractStats.indexOf(this.state.actualStats)
                ) {
                    newStats.push({
                        status: this.state.contractStats[x],
                        passed: true
                    });
                } else {
                    newStats.push({
                        status: this.state.contractStats[x],
                        passed: false
                    });
                }
            }
        }
        return (
            <div className={`status-container ${onModal ? "in-modal" : ""}`}>
                <div className="delimiter first"></div>
                <div
                    className={`line ${this.state.actualStats == "ContratoEncerrado"
                        ? "finished"
                        : ""
                        }`}
                >
                    {newStats.map((value, i) => {
                        return (
                            <div
                                key={i}
                                style={{
                                    width: `${100 / newStats.length -
                                        100 /
                                        newStats.length /
                                        (newStats.length + 1)}%`,
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: value.passed
                                            ? "#e42c2a"
                                            : "#7c7c7c",
                                        width: "100%",
                                        height: value.passed ? 3 : 1
                                    }}
                                ></div>
                                <div
                                    id={`checkpoint${i}`}
                                    className="checkpoint-area"
                                >
                                    <div
                                        className={
                                            value.passed
                                                ? "checkpoint"
                                                : "dontcheck"
                                        }
                                    >
                                        {value.passed ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                                            </svg>
                                        ) : null}
                                        {value.status ==
                                            this.state.actualStats ? (
                                            <div className="checkpoint-bubbly">
                                                <span>
                                                    {value.status
                                                        .toString()
                                                        .replace(
                                                            /([A-Z])/g,
                                                            " $1"
                                                        )
                                                        .replace(/^./, function (
                                                            str
                                                        ) {
                                                            return str.toUpperCase();
                                                        })}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <Tooltip
                                    target={`checkpoint${i}`}
                                    placement="bottom"
                                    isOpen={this.state.tooltipStages[i]}
                                    toggle={() => this.setTooltipStage(i)}
                                >
                                    {value.status
                                        .toString()
                                        .replace(/([A-Z])/g, " $1")
                                        .replace(/^./, function (str) {
                                            return str.toUpperCase();
                                        })}
                                </Tooltip>
                            </div>
                        );
                    })}
                </div>
                <div
                    id="last-checkpoint"
                    className={`delimiter ${this.state.actualStats == "ContratoEncerrado"
                        ? "last"
                        : ""
                        }`}>
                    <div
                        style={{
                            width: `${100 / newStats.length -
                                100 /
                                newStats.length /
                                (newStats.length + 1)}%`,
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        <div
                            id="last-checkpoint"
                            className="checkpoint-area"
                        >
                            <div
                                className={
                                    this.state.actualStats == "ContratoEncerrado"
                                        ? "checkpoint"
                                        : "dontcheck"
                                }
                            >
                                {this.state.actualStats == "ContratoEncerrado" ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                                    </svg>
                                ) : null}
                                {this.state.actualStats ==
                                    "ContratoEncerrado" ? (
                                    <div className="checkpoint-bubbly">
                                        <span>
                                            {this.state.actualStats
                                                .toString()
                                                .replace(
                                                    /([A-Z])/g,
                                                    " $1"
                                                )
                                                .replace(/^./, function (
                                                    str
                                                ) {
                                                    return str.toUpperCase();
                                                })}
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <Tooltip
                    target="last-checkpoint"
                    placement="bottom"
                    isOpen={this.state.lastTooltip}
                    toggle={() =>
                        this.setState(state => ({
                            lastTooltip: !state.lastTooltip
                        }))
                    }
                >
                    Contrato Encerrado
                </Tooltip>
            </div>
        );
    };

    renderConsumption = () => {
        // WIDTH -> 90%
        let consumptionTotal = 0;
        for (let consumption of this.state.contractConsumption) {
            consumptionTotal += consumption.valor;
        }
        let mean = consumptionTotal / this.state.contractConsumption.length;
        let past =
            this.state.contractConsumption.length > 1
                ? this.state.contractConsumption[
                    this.state.contractConsumption.length - 2
                ].valor
                : 0;
        let actually =
            this.state.contractConsumption.length > 0
                ? this.state.contractConsumption[
                    this.state.contractConsumption.length - 1
                ].valor
                : 0;

        return (
            <div className="consumption-container">
                <div className="consumption-feedback">
                    <div
                        className="consumption-mean consumption-line"
                        style={{
                            width:
                                mean > past && mean > actually
                                    ? "100%"
                                    : past > actually
                                        ? `${util.remap(mean, 0, past, 0, 100) < 35
                                            ? 35
                                            : util.remap(
                                                mean,
                                                0,
                                                past,
                                                0,
                                                100
                                            )
                                        }%`
                                        : `${util.remap(
                                            mean,
                                            0,
                                            actually,
                                            0,
                                            100
                                        ) < 35
                                            ? 35
                                            : util.remap(
                                                mean,
                                                0,
                                                actually,
                                                0,
                                                100
                                            )
                                        }%`
                        }}
                    >
                        R$ {util.currenzialize(mean || 0)}
                    </div>
                    <div
                        className="consumption-past-month consumption-line"
                        style={{
                            width:
                                past > mean && past > actually
                                    ? "100%"
                                    : mean > actually
                                        ? `${util.remap(past, 0, mean, 0, 100) < 35
                                            ? 35
                                            : util.remap(
                                                past,
                                                0,
                                                mean,
                                                0,
                                                100
                                            )
                                        }%`
                                        : `${util.remap(
                                            past,
                                            0,
                                            actually,
                                            0,
                                            100
                                        ) < 35
                                            ? 35
                                            : util.remap(
                                                past,
                                                0,
                                                actually,
                                                0,
                                                100
                                            )
                                        }%`
                        }}
                    >
                        R$ {util.currenzialize(past || 0)}
                    </div>
                    <div
                        className="consumption-month consumption-line"
                        style={{
                            width:
                                actually > past && actually > mean
                                    ? "100%"
                                    : past > mean
                                        ? `${util.remap(
                                            actually,
                                            0,
                                            past,
                                            0,
                                            100
                                        ) < 35
                                            ? 35
                                            : util.remap(
                                                actually,
                                                0,
                                                past,
                                                0,
                                                100
                                            )
                                        }%`
                                        : `${util.remap(
                                            actually,
                                            0,
                                            mean,
                                            0,
                                            100
                                        ) < 35
                                            ? 35
                                            : util.remap(
                                                actually,
                                                0,
                                                mean,
                                                0,
                                                100
                                            )
                                        }%`
                        }}
                    >
                        R$ {util.currenzialize(actually || 0)}
                    </div>
                </div>
                <div className="consumption-subtitle">
                    <div className="subtitle">
                        <div
                            className="color"
                            style={{ backgroundColor: "#7c7c7c" }}
                        ></div>
                        <b style={{ marginLeft: 4 }}>Média</b>
                    </div>
                    <div className="subtitle">
                        <div
                            className="color"
                            style={{ backgroundColor: "#f8e73f" }}
                        ></div>
                        <b style={{ marginLeft: 4 }}>
                            {this.state.contractConsumption.length > 1
                                ? this.state.contractConsumption[
                                    this.state.contractConsumption.length - 2
                                ].dataHora
                                : "-"}
                        </b>
                    </div>
                    <div className="subtitle">
                        <div
                            className="color"
                            style={{ backgroundColor: "#e42c2a" }}
                        ></div>
                        <b style={{ marginLeft: 4 }}>
                            {this.state.contractConsumption.length > 0
                                ? this.state.contractConsumption[
                                    this.state.contractConsumption.length - 1
                                ].dataHora
                                : "-"}
                        </b>
                        {/* <span></span> */}
                    </div>
                </div>
            </div>
        );
    };

    renderMoves = () => {
        if (this.state.contractMoves && this.state.contractMoves.length) {
            return this.state.contractMoves.map((value, i) => {
                return (
                    // <div key={i} className="move-box">
                    //     {value.dataAgendamento}
                    // </div>
                    <div key={i} className="move-box">
                        <div className="item-infos">
                            <span className="item-quantity">
                                {value.tpChecklist ? <FaArrowAltCircleUp style={{ fill: "#1bbd14", width: 25, height: 25 }} /> : <FaArrowAltCircleDown style={{ fill: "#e42c2a", width: 25, height: 25 }} />}
                            </span>
                            <div className="right">
                                <span className="item-description">
                                    {`${moment(value.dataAgendamento).format("L LT")}`}{" "}
                                </span>
                                <span class="item-description d-block text-left" style={{ fontSize: 12 }}><b>Tipo de caminhão</b><br /> {value.inCaminhao}</span>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return "Sem programações"
        }

    };

    mobileLayout = props => {
        return (
            <>
                <Row className="contract-options">
                    <Col md={{ size: 4, offset: 2 }}>
                        <div
                            className="session-status"
                            onClick={() => {
                                this.toggleStatsModal();
                            }}
                        >
                            <div className="session-label margin-label">
                                ETAPA DO CONTRATO
                            </div>
                            {this.renderStats()}
                        </div>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <div className="session-consumption">
                            <div className="session-label margin-label">
                                CONSUMO DO CONTRATO
                            </div>
                            {this.renderConsumption()}
                        </div>
                    </Col>
                    <Col md={{ size: 4, offset: 2 }} xs={{ size: 6 }}>
                        <div className="session-balance">
                            <div className="session-label margin-label">
                                SALDO ATUAL EM OBRA
                            </div>
                            <span>Material</span>
                            <span style={{ textAlign: "center" }}>
                                <span className="highlight-input">
                                    {util.currenzialize(this.state.balance_kg)}
                                </span>{" "}
                                <span className="normal-weight">kg</span>
                            </span>
                            <Link
                                to={`/drives?contract=${this.state.contractId}`}
                                className="btn btn-red btn-block btn-moves mt-3"
                                style={{ ...buttonTheme, ...{ fontSize: 9 } }}
                            >
                                NOVA RETIRADA/DEVOLUÇÃO
                            </Link>
                            {/* <span>Locação</span>
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                {this.state.showLeaseBalance ? (
                                    <>
                                        R$ &nbsp;{" "}
                                        <span className="highlight-input">
                                            {util.currenzialize(
                                                this.state.balance_cap
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    <div className="hide-lease-balance" />
                                )}
                                {this.state.showLeaseBalance ? (
                                    <FaEye
                                        onClick={() =>
                                            this.setState(state => ({
                                                showLeaseBalance: !state.showLeaseBalance
                                            }))
                                        }
                                        className="icon"
                                    />
                                ) : (
                                    <FaEyeSlash
                                        onClick={() =>
                                            this.setState(state => ({
                                                showLeaseBalance: !state.showLeaseBalance
                                            }))
                                        }
                                        className="icon"
                                    />
                                )}
                            </span> */}
                        </div>
                    </Col>
                    <Col md={{ size: 4 }} xs={{ size: 6 }}>
                        <div className="session-movement">
                            <div className="session-label margin-label mb-4">
                                AGENDAMENTOS
                            </div>
                            <div className="moves-container ">
                                {this.renderMoves()}
                            </div>
                        </div>
                    </Col>
                    <ContractOptions />
                </Row>
            </>
        );
    };

    desktopLayout = props => {
        return (
            <>
                <Row className="contract-options">
                    <Col md={{ size: 4, offset: 2 }}>
                        <Row>
                            <div
                                className="session-status"
                                onClick={() => {
                                    this.toggleStatsModal();
                                }}
                            >
                                <div className="session-label margin-label">
                                    ETAPA DO CONTRATO
                                </div>
                                {this.renderStats()}
                            </div>
                        </Row>
                        <Row>
                            <div className="session-consumption">
                                <div className="session-label margin-label">
                                    CONSUMO DO CONTRATO
                                </div>
                                {this.renderConsumption()}
                            </div>
                        </Row>
                    </Col>
                    <Col md={{ size: 2 }} xs={{ size: 6 }}>
                        <div className="session-movement">
                            <div className="session-label margin-label mb-4">
                                AGENDAMENTOS
                            </div>
                            <div className="moves-container ">
                                {this.renderMoves()}
                            </div>
                        </div>
                    </Col>
                    <Col md={{ size: 2 }} xs={{ size: 6 }}>
                        <div className="session-balance">
                            <div className="session-label margin-label">
                                SALDO ATUAL EM OBRA
                            </div>
                            <br />
                            <span>Material</span>
                            <span style={{ textAlign: "center" }}>
                                <span className="highlight-input">
                                    {util.currenzialize(this.state.balance_kg)}
                                </span>{" "}
                                <span className="normal-weight">kg</span>
                            </span>
                            <Link
                                to={`/drives?contract=${this.state.contractId}`}
                                className="btn btn-red btn-block btn-moves mt-4"
                                style={buttonTheme}
                            >
                                NOVA RETIRADA/DEVOLUÇÃO
                            </Link>
                            {/* <a
                                href={`https://surveyv2.binds.co/#/link/alR8o2dVGBQgr319xqQ01AqLU6yAdYy57Z3n5Yk6MjwL1vXN/?loop=true&close=true&timeout=30&`}
                                target="_blank"
                                className="btn btn-red btn-block btn-moves mt-4"
                                style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    backgroundColor: 'purple',
                                    borderColor: 'purple'
                                  }}
                            >
                                FAÇA A PESQUISA DE SATISFAÇÃO
                            </a> */}
                            <br />
                            {/* <span>Locação</span>
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                {this.state.showLeaseBalance ? (
                                    <>
                                        R$ &nbsp;{" "}
                                        <span className="highlight-input">
                                            {util.currenzialize(
                                                this.state.balance_cap
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    <div className="hide-lease-balance" />
                                )}
                                {this.state.showLeaseBalance ? (
                                    <FaEye
                                        onClick={() =>
                                            this.setState(state => ({
                                                showLeaseBalance: !state.showLeaseBalance
                                            }))
                                        }
                                        className="icon"
                                    />
                                ) : (
                                    <FaEyeSlash
                                        onClick={() =>
                                            this.setState(state => ({
                                                showLeaseBalance: !state.showLeaseBalance
                                            }))
                                        }
                                        className="icon"
                                    />
                                )}
                            </span> */}
                        </div>
                    </Col>
                    <ContractOptions />
                </Row>
            </>
        );
    };

    noContractLayout = props => (
        <Row className="contract-options">
            <Col
                md={{ size: 12 }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: '1.5rem'
                }}
            >
                <Alert color="warning">
                    Nenhum contrato foi encontrado!
                </Alert>
            </Col>
        </Row>
    );

    loadingLayout = props => (
        <Row className="contract-options">
            <Col
                md={{ size: 12 }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                {/* Carregando{" "} */}
                <Spinner style={{ marginLeft: "1rem", color: "#7c7c7c" }} />
            </Col>
        </Row>
    );

    render() {
        return (
            <>
                <div className="home-container">
                    <Modal centered isOpen={this.state.statsModal}>
                        <ModalHeader>ETAPA DO CONTRATO</ModalHeader>
                        <ModalBody>
                            {this.renderStats(true)}
                            <div className="status-box">
                                {this.state.contractStats.map((value, i) => {
                                    return (
                                        <div key={i} className="stats-info">
                                            <div className="stats-date">
                                                <span className="date">
                                                    {i + 1}.
                                                </span>
                                                <br />
                                                {/* <span className="hour">{moment(value.date).format('HH').toString()}hrs</span> */}
                                            </div>
                                            <span className="stats-action">
                                                {value
                                                    .toString()
                                                    .replace(/([A-Z])/g, " $1")
                                                    .replace(/^./, function (
                                                        str
                                                    ) {
                                                        return str.toUpperCase();
                                                    })}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="btn-hollow"
                                style={buttonTheme}
                                onClick={() => {
                                    this.toggleStatsModal();
                                }}
                            >
                                FECHAR
                            </Button>
                        </ModalFooter>
                    </Modal>
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    {this.state.isLoading
                        ? this.loadingLayout()
                        : this.state.contractId == 0 ?
                            this.noContractLayout()
                            : this.state.mobile
                                ? this.mobileLayout()
                                : this.desktopLayout()}
                </div>
            </>
        );
    }
}

export default BodyHome;
